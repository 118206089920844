import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '../../common';
import styles from '../Settings.styles';
import { Grades } from '../+store/SchoolSettings/SchoolSettings.constants';

const isDistrict = (campusNumber) => campusNumber.length === 6;

const isCCMRAvailable = (campusNumber, gradeEnd) => isDistrict(campusNumber) || gradeEnd === '12';

function gradeStartValidation(value) {
  const startIndex = Grades.findIndex((g) => g.code === value);
  const endIndex = Grades.findIndex((g) => g.code === this.resolve(Yup.ref('gradeEnd')));
  return startIndex <= endIndex;
}

function gradeEndValidation(value) {
  const endIndex = Grades.findIndex((g) => g.code === value);
  const startIndex = Grades.findIndex((g) => g.code === this.resolve(Yup.ref('gradeStart')));
  return startIndex <= endIndex;
}

class Settings extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    status: PropTypes.shape({
      error: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    schoolList: PropTypes.object,
    profile: PropTypes.object,
    isCCMRFileExists: PropTypes.bool,
    isgraduationDataFileExists: PropTypes.bool,
    defaultSettings: PropTypes.object,
    save: PropTypes.func.isRequired,
    districtOrSchoolData: PropTypes.any,
    getDistrictOrSchoolData: PropTypes.func.isRequired,
    gradeSpanTable: PropTypes.object,
  };
  indicatorsNames = [
    { key: 'all', name: 'All' },
    { key: 'white', name: 'White' },
    { key: 'black', name: 'African American' },
    { key: 'hispanic', name: 'Hispanic' },
    { key: 'nativeAmerican', name: 'Native American' },
    { key: 'asian', name: 'asian' },
    { key: 'pacificIslander', name: 'Pacific Islander' },
    { key: 'twoOrMoreRaces', name: 'Two Or More Races' },
    { key: 'ecoDis', name: 'EcoDis' },
    { key: 'specialEdCurrent', name: 'Special Ed Current' },
    { key: 'specialEdFormer', name: 'Special Ed Former' },
    { key: 'lep', name: 'Lep' },
    { key: 'continuouslyEnrolled', name: 'Continuously Enrolled' },
    { key: 'nonContinuouslyEnrolled', name: 'NonContinuously Enrolled' },
    { key: 'highFocus', name: 'High Focus' },
  ];

  state = {
    campus: '',
  };
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    const { profile, schoolList } = nextProps;
    const { campus } = prevState;
    const campuses = Object.keys(schoolList);
    if (!campus && profile && profile.role === 'campusAdmin' && campuses.length) {
      return {
        campus: campuses[0],
      };
    }
    return {};
  }

  onReset = (values) => () => {
    const { save, profile, defaultSettings } = this.props;
    const settings = {
      name: defaultSettings[values.campus].name,
      ecoDis: defaultSettings[values.campus].ecoDis,
      telpasData: null,
      isAEA: defaultSettings[values.campus].isAEA,
    };
    if (!isDistrict(values.campus)) {
      settings.gradeStart = defaultSettings[values.campus].gradeStart;
      settings.gradeEnd = defaultSettings[values.campus].gradeEnd;
      settings.firstLowestPerformingSubgroupsPriorYear =
        defaultSettings[values.campus].firstLowestPerformingSubgroupsPriorYear;
      settings.secondLowestPerformingSubgroupsPriorYear =
        defaultSettings[values.campus].secondLowestPerformingSubgroupsPriorYear;
    }
    if (isCCMRAvailable(values.campus, values.gradeEnd)) {
      settings.ccmrSource = defaultSettings[values.campus].ccmrSource || '';
    }

    settings.graduationDataSource = defaultSettings[values.campus].graduationDataSource || '';

    save(profile, values.campus, settings);
  };

  onSave = (values) => {
    const { save, profile, gradeSpanTable, schoolList } = this.props;
    const { campus } = this.state;
    const numeratorVal = Math.round((Number(values.telpasRate) * Number(values.telpasDenom)) / 100);
    const settings = {
      ecoDis: values.ecoDis,
      isAEA: values.isAEA,
      telpasData: {
        numerator: numeratorVal,
        denominator: Number(values.telpasDenom),
        rate: Number(values.telpasRate),
      },
      name: values.name,
    };
    if (!isDistrict(values.campus)) {
      const {
        gradeStart,
        gradeEnd,
        firstLowestPerformingSubgroupsPriorYear,
        secondLowestPerformingSubgroupsPriorYear,
      } = values;
      settings.gradeStart = gradeStart;
      settings.gradeEnd = gradeEnd;

      settings.firstLowestPerformingSubgroupsPriorYear = firstLowestPerformingSubgroupsPriorYear;
      settings.secondLowestPerformingSubgroupsPriorYear = secondLowestPerformingSubgroupsPriorYear;

      settings.type = schoolList[campus].type;
      if (gradeSpanTable && gradeSpanTable[gradeStart] && gradeSpanTable[gradeStart][gradeEnd]) {
        settings.type = gradeSpanTable[gradeStart][gradeEnd];
      }
    }
    if (isCCMRAvailable(values.campus, values.gradeEnd)) {
      settings.ccmrSource = values.ccmrSource;

      if (settings.ccmrIndicators == null) {
        settings.ccmrIndicators = {};
      }

      if (settings.ccmrIndicators.studentAchievement == null) {
        settings.ccmrIndicators.studentAchievement = {};
      }

      if (settings.ccmrIndicators.studentAchievement.numerator == null) {
        settings.ccmrIndicators.studentAchievement.numerator = {};
      }
      if (settings.ccmrIndicators.studentAchievement.denominator == null) {
        settings.ccmrIndicators.studentAchievement.denominator = {};
      }
      if (settings.ccmrIndicators.studentAchievement.rate == null) {
        settings.ccmrIndicators.studentAchievement.rate = {};
      }

      if (settings.ccmrIndicators.closingTheGap == null) {
        settings.ccmrIndicators.closingTheGap = {};
      }
      if (settings.ccmrIndicators.closingTheGap.numerator == null) {
        settings.ccmrIndicators.closingTheGap.numerator = {};
      }
      if (settings.ccmrIndicators.closingTheGap.denominator == null) {
        settings.ccmrIndicators.closingTheGap.denominator = {};
      }
      // if (settings.ccmrIndicators.closingTheGap.rate == null) {
      //  settings.ccmrIndicators.closingTheGap.rate = {};
      //  }

      settings.ccmrIndicators.studentAchievement.numerator.all = values['ccmrD1Numerator'] || '';
      settings.ccmrIndicators.studentAchievement.denominator.all = values['ccmrD1Denominator'] || '';
      // settings.ccmrIndicators.studentAchievement.rate.all = values['ccmrD1Rate'];
      this.indicatorsNames.forEach((ind) => {
        settings.ccmrIndicators.closingTheGap.numerator[ind.key] = values['ccmrD3' + ind.key + 'Numerator'] || '';
        settings.ccmrIndicators.closingTheGap.denominator[ind.key] = values['ccmrD3' + ind.key + 'Denominator'] || '';
        //  settings.ccmrIndicators.closingTheGap.rate[ind.key] = values['ccmrD3' + ind.key + 'Rate'];
      });
    }

    settings.graduationDataSource = values.graduationDataSource;

    if (settings.graduationDataIndicators == null) {
      settings.graduationDataIndicators = {};
    }

    if (settings.graduationDataIndicators.studentAchievement == null) {
      settings.graduationDataIndicators.studentAchievement = {};
    }

    if (settings.graduationDataIndicators.studentAchievement.numerator == null) {
      settings.graduationDataIndicators.studentAchievement.numerator = {};
    }
    if (settings.graduationDataIndicators.studentAchievement.denominator == null) {
      settings.graduationDataIndicators.studentAchievement.denominator = {};
    }
    if (settings.graduationDataIndicators.studentAchievement.rate == null) {
      settings.graduationDataIndicators.studentAchievement.rate = {};
    }

    if (settings.graduationDataIndicators.closingTheGap == null) {
      settings.graduationDataIndicators.closingTheGap = {};
    }
    if (settings.graduationDataIndicators.closingTheGap.numerator == null) {
      settings.graduationDataIndicators.closingTheGap.numerator = {};
    }
    if (settings.graduationDataIndicators.closingTheGap.denominator == null) {
      settings.graduationDataIndicators.closingTheGap.denominator = {};
    }

    if (settings.graduationDataIndicators.closingTheGap.rate == null) {
      settings.graduationDataIndicators.closingTheGap.rate = {};
    }

    settings.graduationDataIndicators.studentAchievement.numerator.all = values['graduationDataD1Numerator'] || '';
    settings.graduationDataIndicators.studentAchievement.denominator.all = values['graduationDataD1Denominator'] || '';
    settings.graduationDataIndicators.studentAchievement.rate.all = values['graduationDataD1Rate'] || '';

    this.indicatorsNames.forEach((ind) => {
      settings.graduationDataIndicators.closingTheGap.numerator[ind.key] =
        values['graduationDataD3' + ind.key + 'Numerator'] || '';
      settings.graduationDataIndicators.closingTheGap.denominator[ind.key] =
        values['graduationDataD3' + ind.key + 'Denominator'] || '';

      settings.graduationDataIndicators.closingTheGap.rate[ind.key] =
        values['graduationDataD3' + ind.key + 'Rate'] || '';
    });

    save(profile, values.campus, settings);
  };

  handleChange = (handleChange, setFieldValue, name) => (e) => {
    handleChange(e);
    const { schoolList, getDistrictOrSchoolData } = this.props;
    const { value } = e.target;
    switch (name) {
      case 'campus': {
        let campus = value;
        this.setState({ campus: campus });
        let gradeEnd = '';
        if (campus && schoolList[campus]) {
          const settings = schoolList[campus];
          gradeEnd = settings != null ? settings.gradeEnd : null;
        }

        getDistrictOrSchoolData({ campusNumber: campus });

        break;
      }
      default:
    }
  };
  isCCMRManual(value) {
    return value == 'manual';
  }

  isGradeDateManual(value) {
    return value == 'manual';
  }

  drawD1ccmrIndicators = (values, handleChange, handleBlur, classes, errors) => {
    return (
      <div>
        <div className={classes.ccmrD1Header}>
          <InputLabel className={classes.helperTxt} shrink>
            CCMR Student Achievement (D1 All)
          </InputLabel>
        </div>
        <div id="ccmrD1Manual" className={classes.ccmrD1Manual}>
          <div className={classes.ccmrD1Row}>
            <FormControl className={classes.formControl} style={{ marginLeft: 2 }} error={!!errors.ccmrNumerator}>
              <InputLabel className={classes.helperTxt} shrink>
                CCMR Numerator
              </InputLabel>
              <TextField
                name="ccmrD1Numerator"
                className={classes.ccmrD1NumeratorText}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ccmrD1Numerator}
              />
              <FormHelperText style={{ paddingRight: 4 }}>
                {errors.ccmrD1Numerator || 'CCMR D1 Numerator'}
              </FormHelperText>
            </FormControl>

            <FormControl className={classes.formControl} style={{ marginRight: 2 }} error={!!errors.ccmrD1Denominator}>
              <InputLabel className={classes.helperTxt} shrink>
                CCMR Denominator
              </InputLabel>
              <TextField
                name="ccmrD1Denominator"
                className={classes.ccmrD1DenominatorText}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ccmrD1Denominator}
              />
              <FormHelperText style={{ paddingRight: 4 }}>
                {errors.ccmrD1Denominator || 'CCMR D1 Denominator'}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
      </div>
    );
  };
  drawD3ccmrIndicators = (values, handleChange, handleBlur, classes, errors) => {
    return (
      <div>
        <div className={classes.ccmrD3Header}>
          <InputLabel className={classes.helperTxt} shrink>
            CCMR ClosingTheGap (D3)
          </InputLabel>
        </div>
        <div className={classes.ccmrD3Manual}>
          {this.indicatorsNames &&
            this.indicatorsNames.length > 0 &&
            this.indicatorsNames.map((ind) => (
              <div key={'divD3ccmr' + ind.key}>
                <div className={classes.ccmrD3Header}>
                  <InputLabel className={classes.helperTxt} shrink>
                    D3 {ind.name}
                  </InputLabel>
                </div>
                <div className={classes.ccmrD3Row}>
                  <FormControl className={classes.formControl} style={{ marginLeft: 2 }} error={!!errors.ccmrNumerator}>
                    <InputLabel className={classes.helperTxt} shrink>
                      CCMR Numerator
                    </InputLabel>
                    <TextField
                      name={'ccmrD3' + ind.key + 'Numerator'}
                      className={classes['ccmrD3' + ind.key + 'NumeratorText']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values['ccmrD3' + ind.key + 'Numerator']}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors['ccmrD3' + ind.key + 'Numerator'] || 'CCMR D3 ' + ind.name + ' Numerator'}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 2 }}
                    error={!!errors['ccmrD3' + ind.name + 'Denominator']}
                  >
                    <InputLabel className={classes.helperTxt} shrink>
                      CCMR Denominator
                    </InputLabel>
                    <TextField
                      name={'ccmrD3' + ind.key + 'Denominator'}
                      className={classes['ccmrD3' + ind.key + 'DenominatorText']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values['ccmrD3' + ind.key + 'Denominator']}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors['ccmrD3' + ind.name + 'Denominator'] || 'CCMR D3 ' + ind.name + ' Denominator'}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  drawD1graduationDataIndicators = (values, handleChange, handleBlur, classes, errors) => {
    return (
      <div>
        <div className={classes.graduationDataD1Header}>
          <InputLabel className={classes.helperTxt} shrink>
            Graduation Data Student Achievement (D1 All)
          </InputLabel>
        </div>
        <div id="graduationDataD1Manual" className={classes.graduationDataD1Manual}>
          <div className={classes.graduationDataD1Row}>
            <FormControl
              className={classes.formControl}
              style={{ marginLeft: 2 }}
              error={!!errors.graduationDataNumerator}
            >
              <InputLabel className={classes.helperTxt} shrink>
                Graduation Data Numerator
              </InputLabel>
              <TextField
                name="graduationDataD1Numerator"
                className={classes.graduationDataD1NumeratorText}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.graduationDataD1Numerator}
              />
              <FormHelperText style={{ paddingRight: 4 }}>
                {errors.graduationDataD1Numerator || 'Graduation Data D1 Numerator'}
              </FormHelperText>
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{ marginRight: 2 }}
              error={!!errors.graduationDataD1Denominator}
            >
              <InputLabel className={classes.helperTxt} shrink>
                Graduation Data Denominator
              </InputLabel>
              <TextField
                name="graduationDataD1Denominator"
                className={classes.graduationDataD1DenominatorText}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.graduationDataD1Denominator}
              />
              <FormHelperText style={{ paddingRight: 4 }}>
                {errors.graduationDataD1Denominator || 'Graduation Data D1 Denominator'}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              style={{ marginLeft: 2 }}
              error={!!errors.graduationDataD1Rate}
            >
              <InputLabel className={classes.helperTxt} shrink>
                Graduation Data Rate
              </InputLabel>
              <TextField
                name="graduationDataD1Rate"
                className={classes.graduationDataD1RateText}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.graduationDataD1Rate}
              />
              <FormHelperText style={{ paddingRight: 4 }}>
                {errors.graduationDataD1Rate || 'Graduation Data D1 Rate'}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
      </div>
    );
  };
  drawD3graduationDataIndicators = (values, handleChange, handleBlur, classes, errors) => {
    return (
      <div>
        <div className={classes.graduationDataD3Header}>
          <InputLabel className={classes.helperTxt} shrink>
            Graduation Data ClosingTheGap (D3)
          </InputLabel>
        </div>
        <div className={classes.graduationDataD3Manual}>
          {this.indicatorsNames &&
            this.indicatorsNames.length > 0 &&
            this.indicatorsNames.map((ind) => (
              <div key={'divD3graduationData' + ind.key}>
                <div className={classes.graduationDataD3Header}>
                  <InputLabel className={classes.helperTxt} shrink>
                    D3 {ind.name}
                  </InputLabel>
                </div>
                <div className={classes.graduationDataD3Row}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginLeft: 2 }}
                    error={!!errors.graduationDataNumerator}
                  >
                    <InputLabel className={classes.helperTxt} shrink>
                      Graduation Data Numerator
                    </InputLabel>
                    <TextField
                      name={'graduationDataD3' + ind.key + 'Numerator'}
                      className={classes['graduationDataD3' + ind.key + 'NumeratorText']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values['graduationDataD3' + ind.key + 'Numerator']}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors['graduationDataD3' + ind.key + 'Numerator'] ||
                        'Graduation Data D3 ' + ind.name + ' Numerator'}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 2 }}
                    error={!!errors['graduationDataD3' + ind.name + 'Denominator']}
                  >
                    <InputLabel className={classes.helperTxt} shrink>
                      Graduation Data Denominator
                    </InputLabel>
                    <TextField
                      name={'graduationDataD3' + ind.key + 'Denominator'}
                      className={classes['graduationDataD3' + ind.key + 'DenominatorText']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values['graduationDataD3' + ind.key + 'Denominator']}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors['graduationDataD3' + ind.name + 'Denominator'] ||
                        'Graduation Data D3 ' + ind.name + ' Denominator'}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    className={classes.formControl}
                    style={{ marginLeft: 2 }}
                    error={!!errors['graduationDataD3' + ind.name + 'Rate']}
                  >
                    <InputLabel className={classes.helperTxt} shrink>
                      Graduation Data Rate
                    </InputLabel>
                    <TextField
                      name={'graduationDataD3' + ind.key + 'Rate'}
                      className={classes['graduationDataD3' + ind.key + 'RateText']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values['graduationDataD3' + ind.key + 'Rate']}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors['graduationDataD3' + ind.name + 'Rate'] || 'Graduation Data D3 ' + ind.name + ' Rate'}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };
  render() {
    const { classes, schoolList, profile, isCCMRFileExists, isgraduationDataFileExists } = this.props;
    const { campus } = this.state;

    const initialValues = {
      campus,
      ecoDis: 0,
      isAEA: false,
      ccmrSource: '',
      graduationDataSource: '',
      telpasDenom: 1,
      telpasRate: 100,
      gradeStart: '',
      gradeEnd: '',
      name: '',
      firstLowestPerformingSubgroupsPriorYear: '',
      secondLowestPerformingSubgroupsPriorYear: '',
    };

    if (campus && schoolList[campus]) {
      const settings = schoolList[campus];
      initialValues.name = settings.name;
      initialValues.ecoDis = settings.ecoDis;
      initialValues.isAEA = settings.isAEA;
      if (settings.telpasData) {
        initialValues.telpasDenom = settings.telpasData.denominator;
        initialValues.telpasRate = settings.telpasData.rate;
      } else {
        initialValues.telpasDenom = Number.isNaN(Number(settings.d3.telpas.denominator))
          ? ''
          : settings.d3.telpas.denominator;
        initialValues.telpasRate = Number.isNaN(Number(settings.d3.telpas.rate)) ? '' : settings.d3.telpas.rate;
      }
      if (isCCMRAvailable(campus, settings.gradeEnd)) {
        initialValues.ccmrSource = settings.ccmrSource || '';
      }
      if (!isDistrict(campus)) {
        initialValues.gradeStart = settings.gradeStart;
        initialValues.gradeEnd = settings.gradeEnd;

        initialValues.firstLowestPerformingSubgroupsPriorYear = settings.firstLowestPerformingSubgroupsPriorYear;
        initialValues.secondLowestPerformingSubgroupsPriorYear = settings.secondLowestPerformingSubgroupsPriorYear;
      }

      if (settings.ccmrIndicators) {
        if (settings.ccmrIndicators.studentAchievement) {
          // d1 is student Achievement
          initialValues.ccmrD1Denominator = settings.ccmrIndicators.studentAchievement.denominator.all;
          initialValues.ccmrD1Numerator = settings.ccmrIndicators.studentAchievement.numerator.all;
          //initialValues.ccmrD1Rate = settings.ccmrIndicators.studentAchievement.rate.all;
        }

        if (settings.ccmrIndicators.closingTheGap) {
          // d3 is
          this.indicatorsNames.forEach((ind) => {
            initialValues['ccmrD3' + ind.key + 'Denominator'] =
              settings.ccmrIndicators.closingTheGap.denominator[ind.key];
            initialValues['ccmrD3' + ind.key + 'Numerator'] = settings.ccmrIndicators.closingTheGap.numerator[ind.key];
            // initialValues['ccmrD3' + ind.key + 'Rate'] = settings.ccmrIndicators.closingTheGap.rate[ind.key];
          });
        }
      }
      initialValues.graduationDataSource = settings.graduationDataSource || '';

      if (settings.graduationDataIndicators) {
        if (settings.graduationDataIndicators.studentAchievement) {
          // d1 is student Achievement
          if (settings.graduationDataIndicators.studentAchievement.denominator) {
            initialValues.graduationDataD1Denominator =
              settings.graduationDataIndicators.studentAchievement.denominator.all;
          }
          if (settings.graduationDataIndicators.studentAchievement.numerator) {
            initialValues.graduationDataD1Numerator =
              settings.graduationDataIndicators.studentAchievement.numerator.all;
          }

          if (settings.graduationDataIndicators.studentAchievement.rate) {
            initialValues.graduationDataD1Rate = settings.graduationDataIndicators.studentAchievement.rate.all;
          }
        }
        if (settings.graduationDataIndicators.closingTheGap) {
          // d3 is
          this.indicatorsNames.forEach((ind) => {
            if (settings.graduationDataIndicators.closingTheGap.denominator) {
              initialValues['graduationDataD3' + ind.key + 'Denominator'] =
                settings.graduationDataIndicators.closingTheGap.denominator[ind.key];
            }
            if (settings.graduationDataIndicators.closingTheGap.numerator) {
              initialValues['graduationDataD3' + ind.key + 'Numerator'] =
                settings.graduationDataIndicators.closingTheGap.numerator[ind.key];
            }
            if (settings.graduationDataIndicators.closingTheGap.rate) {
              initialValues['graduationDataD3' + ind.key + 'Rate'] =
                settings.graduationDataIndicators.closingTheGap.rate[ind.key];
            }
          });
        }
      }
    }

    const schoolListEntries = Object.entries(schoolList).sort(function(a, b) {
      return a[1].name.localeCompare(b[1].name);
    });
    let ind = 'all';

    return (
      <Formik
        key="Formik1"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          campus: Yup.string().required(),
          ccmrSource: Yup.string(),
          name: Yup.string().required('School name is required'),
          gradeStart: Yup.string().test('gradSpan', 'Start grade must be less than end grade', gradeStartValidation),
          gradeEnd: Yup.string().test('gradSpan', 'Start grade must be less than end grade', gradeEndValidation),
          ecoDis: Yup.string()
            .test('number', 'Must be a number between 0 and 100', (value) => {
              const percent = Number(value);
              if (Number.isNaN(percent)) {
                return false;
              }
              if (percent < 0) {
                return false;
              }
              return percent <= 100;
            })
            .required(),
          telpasDenom: Yup.number()
            .integer()
            .positive()
            .required(),
          telpasRate: Yup.number()
            .min(0, 'Value must be at least 0')
            .max(100, 'Value must be at most 100')
            .positive()
            .required(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          this.onSave(values);
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            {profile &&
              (profile.role === 'districtAdmin' || schoolListEntries.length > 1 ? (
                <FormControl className={classes.formControl}>
                  <InputLabel className={classes.helperTxt} shrink>
                    School / District
                  </InputLabel>
                  <Select
                    value={values.campus}
                    onChange={this.handleChange(handleChange, setFieldValue, 'campus')}
                    onBlur={handleBlur}
                    name="campus"
                    displayEmpty
                    className={classes.campusSelect}
                  >
                    <MenuItem value="" disabled>
                      Select a campus
                    </MenuItem>
                    {schoolListEntries.map(([campusNumber, campusInfo]) => (
                      <MenuItem key={campusNumber} value={campusNumber}>
                        {`${campusNumber} - ${campusInfo.name} (${isDistrict(campusNumber) ? 'District' : 'School'})`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ paddingRight: 4 }}>Please select a school or district</FormHelperText>
                </FormControl>
              ) : (
                <Typography>
                  {`${values.campus} ${schoolList[values.campus] ? ` - ${schoolList[values.campus].name}` : ''} (${
                    isDistrict(values.campus) ? 'District' : 'School'
                  })`}
                </Typography>
              ))}
            {values.campus && (
              <React.Fragment>
                <FormControl className={classes.formControl} error={!!touched.name && !!errors.name}>
                  <InputLabel className={classes.helperTxt} shrink>
                    {`${isDistrict(values.campus) ? 'District' : 'School'} Name`}
                  </InputLabel>
                  <TextField
                    name="name"
                    className={classes.ecoDisText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    disabled={!profile.isSuperAdmin}
                  />
                  {errors.name && <FormHelperText style={{ paddingRight: 4 }}>{errors.name}</FormHelperText>}
                </FormControl>
                {!isDistrict(values.campus) && (
                  <div>
                    <div className={classes.gradeSpanRow}>
                      <FormControl className={classes.formControl} error={!!errors.gradeStart}>
                        <InputLabel>Grade Span - Start</InputLabel>
                        <Select
                          value={values.gradeStart}
                          onChange={this.handleChange(handleChange, setFieldValue, 'gradeStart')}
                          onBlur={handleBlur}
                          name="gradeStart"
                          className={classes.gradeStartSelect}
                          disabled={!profile.isSuperAdmin}
                        >
                          <MenuItem value="" disabled>
                            Select a start grade
                          </MenuItem>
                          {Grades.map(({ code, label }) => (
                            <MenuItem key={code} value={code}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ paddingRight: 4 }}>
                          {errors.gradeStart || "What's the lowest grade taught at this school"}
                        </FormHelperText>
                      </FormControl>
                      <FormControl className={classes.formControl} error={!!errors.gradeEnd}>
                        <InputLabel>Grade Span - End</InputLabel>
                        <Select
                          value={values.gradeEnd}
                          onChange={this.handleChange(handleChange, setFieldValue, 'gradeEnd')}
                          onBlur={handleBlur}
                          name="gradeEnd"
                          className={classes.gradeEndSelect}
                          disabled={!profile.isSuperAdmin}
                        >
                          <MenuItem value="" disabled>
                            Select an end grade
                          </MenuItem>
                          {Grades.map(({ code, label }) => (
                            <MenuItem key={code} value={code}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ paddingRight: 4 }}>
                          {errors.gradeEnd || "What's the highest grade taught at this school"}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel className={classes.helperTxt} shrink>
                          First Lowest Performing Subgroups Prior Year
                        </InputLabel>
                        <Select
                          value={values.firstLowestPerformingSubgroupsPriorYear}
                          onChange={this.handleChange(
                            handleChange,
                            setFieldValue,
                            'firstLowestPerformingSubgroupsPriorYear'
                          )}
                          onBlur={handleBlur}
                          name="firstLowestPerformingSubgroupsPriorYear"
                          className={classes.gradeEndSelect}
                          disabled={!profile.isSuperAdmin}
                        >
                          <MenuItem value="" disabled>
                            Select First Lowest Performing Subgroups Prior Year
                          </MenuItem>
                          {this.indicatorsNames.map((ind) => (
                            <MenuItem key={ind.key} value={ind.name}>
                              {ind.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ paddingRight: 4 }}>
                          Please select First Lowest Performing Subgroups Prior Year
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel className={classes.helperTxt} shrink>
                          Second Lowest Performing Subgroups Prior Year
                        </InputLabel>
                        <Select
                          value={values.secondLowestPerformingSubgroupsPriorYear}
                          onChange={this.handleChange(
                            handleChange,
                            setFieldValue,
                            'secondLowestPerformingSubgroupsPriorYear'
                          )}
                          onBlur={handleBlur}
                          name="secondLowestPerformingSubgroupsPriorYear"
                          className={classes.gradeEndSelect}
                          disabled={!profile.isSuperAdmin}
                        >
                          <MenuItem value="" disabled>
                            Select Second Lowest Performing Subgroups Prior Year
                          </MenuItem>

                          {this.indicatorsNames.map((ind) => (
                            <MenuItem key={ind.key} value={ind.name}>
                              {ind.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ paddingRight: 4 }}>
                          Please Second Lowest Performing Subgroups Prior Year
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                )}
                <FormControl className={classes.formControl} error={!!errors.ecoDis}>
                  <InputLabel className={classes.helperTxt} shrink>
                    EcoDis
                  </InputLabel>
                  <TextField
                    name="ecoDis"
                    className={classes.ecoDisText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ecoDis}
                    disabled={!profile.isSuperAdmin}
                  />
                  <FormHelperText style={{ paddingRight: 4 }}>
                    {errors.ecoDis || 'Percent of economically disadvantaged students'}
                  </FormHelperText>
                </FormControl>

                <div className={classes.telpasRow}>
                  <FormControl className={classes.formControl} style={{ marginRight: 4 }} error={!!errors.telpasRate}>
                    <InputLabel className={classes.helperTxt} shrink>
                      TELPAS - Percent Progress
                    </InputLabel>
                    <TextField
                      name="telpasRate"
                      className={classes.telpasRateText}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telpasRate}
                      disabled={!profile.isSuperAdmin}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors.telpasRate || 'Percent of English learners showing progress'}
                    </FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formControl} style={{ marginLeft: 4 }} error={!!errors.telpasDenom}>
                    <InputLabel className={classes.helperTxt} shrink>
                      TELPAS - Number of English Learners
                    </InputLabel>
                    <TextField
                      name="telpasDenom"
                      className={classes.telpasDenomText}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telpasDenom}
                      disabled={!profile.isSuperAdmin}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {errors.telpasDenom || 'Number of English learners'}
                    </FormHelperText>
                  </FormControl>
                </div>

                {isCCMRAvailable(values.campus, values.gradeEnd) && (
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.helperTxt} shrink>
                        CCMR Source
                      </InputLabel>
                      <Select
                        value={values.ccmrSource}
                        onChange={this.handleChange(handleChange, setFieldValue, 'ccmrSource')}
                        onBlur={handleBlur}
                        name="ccmrSource"
                        displayEmpty
                        className={classes.campusSelect}
                        disabled={!profile.isSuperAdmin}
                      >
                        <MenuItem value="" disabled>
                          Select a CCMR Source
                        </MenuItem>
                        <MenuItem key="0" value="tracker">
                          CCMR Tracker
                        </MenuItem>
                        <MenuItem key="1" value="file" disabled={!isCCMRFileExists}>
                          Prior Year's Files
                        </MenuItem>
                        <MenuItem key="2" value="manual">
                          Manual Settings
                        </MenuItem>
                      </Select>
                      <FormHelperText style={{ paddingRight: 4 }}>Please select CCMR Source</FormHelperText>
                    </FormControl>

                    {this.isCCMRManual(values.ccmrSource) && (
                      <div id="ccmrManual">
                        {this.drawD1ccmrIndicators(values, handleChange, handleBlur, classes, errors)}
                        {this.drawD3ccmrIndicators(values, handleChange, handleBlur, classes, errors)}
                      </div>
                    )}
                  </div>
                )}

                <p></p>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.helperTxt} shrink>
                      Graduation Data Source
                    </InputLabel>
                    <Select
                      value={values.graduationDataSource}
                      onChange={this.handleChange(handleChange, setFieldValue, 'graduationDataSource')}
                      onBlur={handleBlur}
                      name="graduationDataSource"
                      displayEmpty
                      className={classes.campusSelect}
                      disabled={!profile.isSuperAdmin}
                    >
                      <MenuItem value="" disabled>
                        Select a Graduation Data Source
                      </MenuItem>
                      <MenuItem key="1" value="file">
                        Prior Year's Files
                      </MenuItem>
                      <MenuItem key="2" value="manual">
                        Manual Settings
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ paddingRight: 4 }}>Please select Graduation Data Source</FormHelperText>
                  </FormControl>

                  {this.isGradeDateManual(values.graduationDataSource) && (
                    <div id="graduationDataManual">
                      {this.drawD1graduationDataIndicators(values, handleChange, handleBlur, classes, errors)}
                      {this.drawD3graduationDataIndicators(values, handleChange, handleBlur, classes, errors)}
                    </div>
                  )}
                </div>

                <FormControlLabel
                  disabled={!profile.isSuperAdmin}
                  error={errors.isAEA}
                  control={<Checkbox checked={values.isAEA} onChange={handleChange} name="isAEA" color="primary" />}
                  label="This school/district is an Alternative Education Accountability Campus or District (AEA)"
                />
                {profile && profile.isSuperAdmin == true && (
                  <div style={{ marginLeft: -8, marginRight: -8 }}>
                    <Button color="dark" variant="contained" submit>
                      Update Settings
                    </Button>
                    <Button
                      style={{ marginLeft: '10px' }}
                      color="dark"
                      variant="contained"
                      onClick={this.onReset(values)}
                    >
                      Reset Settings
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
            <Typography className={classes.noteText} variant="h1">
              Note: These settings will affect your school/district’s A-F score and Flight Plan report
            </Typography>
          </Form>
        )}
      </Formik>
    );
  }
}
export default withStyles(styles)(Settings);
