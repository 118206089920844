import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { LoadingCircle, Dropzone, TitleBackButton, isMultipleCampusAdmin } from '../../common';
import { OtherAssessmentTypes } from '../+store/assessments/assessments.types';
import UploadModal from '../UploadModal';
import DeleteModal from '../DeleteModal';
import AssessmentGroupManager from '../AssessmentGroupManager';
import { storage } from '../../App';
import moment from 'moment';
const styles = (theme) => ({
  root: {
    width: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  background: {},
  content: {
    height: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {},
  dropzone: {
    border: '1px solid #656565',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  dropzoneWrapper: {
    height: '300px',
    marginBottom: '36px',
  },
  dropzoneText: {
    fontSize: '24px',
    color: '#a9a9a9',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  tableWrapper: {
    marginBottom: '24px',
    background: 'unset',
  },
  headerGrid: {
    alignItems: 'center',
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  deleteModal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  headerDiv: {
    marginLeft: '26px',
    marginTop: '3px',
  },
  tableCell: {
    padding: '4px 16px',
    textTransform: 'capitalize',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.unit,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
});

class AssessmentManager extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    needsInit: PropTypes.bool.isRequired,
    addAssessmentsListener: PropTypes.func.isRequired,
    removeAssessmentsListener: PropTypes.func.isRequired,
    status: PropTypes.shape({
      error: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    assessmentList: PropTypes.array.isRequired,
    onDrop: PropTypes.func.isRequired,
    uploadStaging: PropTypes.object,
    uploadStagingSDR: PropTypes.object,
    onCancelUpload: PropTypes.func.isRequired,
    uploadProgressMsg: PropTypes.string.isRequired,
    doUpload: PropTypes.func.isRequired,
    doUploadSDR: PropTypes.func.isRequired,
    uploadSDRFilesResult: PropTypes.any,
    clearUploadSDRResult: PropTypes.func.isRequired,
    uploadingSDR: PropTypes.bool,
    uploadedSDR: PropTypes.bool,
    uploadOpen: PropTypes.bool.isRequired,
    onUploadBtnClick: PropTypes.func.isRequired,
    onCancelUploadClick: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    schoolList: PropTypes.object,
    uploaderList: PropTypes.array,
    onAssessmentVisibilityBySuperAdminOnlyChange: PropTypes.func.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    onReparseClick: PropTypes.func.isRequired,
  };
  inputSDRFiles = React.createRef(); // Ref for the file input

  state = {
    errorMsgs: [],
    isDeleteModalOpen: false,
    assessmentToBeDeleted: null,
    selectedUploader: '',
    interimTemplateClicked: false,
    selectedSDRFiles: [], // Store selected files
    dialogOpenSDR: false,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate(prevProps, prevState) {
    this.shouldInit();
    const { assessmentList } = this.props;
    if (prevState.isDeleteModalOpen && assessmentList.length < prevProps.assessmentList.length) {
      this.setState({
        isDeleteModalOpen: false,
        assessmentToBeDeleted: null,
      });
    }
  }

  componentWillUnmount() {
    const { profile, removeAssessmentsListener } = this.props;
    if (profile) {
      removeAssessmentsListener(profile);
    }
  }

  handleChangeFilter = (event) => {
    this.setState({ selectedUploader: event.target.value });
  };

  renderOwnerFilter = () => {
    const { classes, uploaderList } = this.props;
    const { selectedUploader } = this.state;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="uploaded-by">Uploaded By</InputLabel>
        <Select
          autoWidth
          value={selectedUploader}
          onChange={this.handleChangeFilter}
          inputProps={{ name: 'uploaded-by', id: 'uploaded-by' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {uploaderList.map((name) => (
            <MenuItem key={name.value} value={name.value}>
              {_.startCase(name.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  deleteIcon = (assessment) => {
    const canDelete = this.canDelete(assessment.owner);
    return (
      <IconButton onClick={this.deleteButtonClicked(assessment)} disabled={!canDelete}>
        <DeleteIcon color={canDelete ? 'error' : 'disabled'} />
      </IconButton>
    );
  };

  deleteButtonClicked = (assessment) => () => {
    this.setState({
      isDeleteModalOpen: true,
      assessmentToBeDeleted: assessment,
    });
  };

  renderOwner = (owner) => {
    const { schoolList } = this.props;
    const didSchoolListLoad = schoolList && Object.keys(schoolList).length;
    if (owner !== 'district' && didSchoolListLoad) {
      if (typeof owner === 'string') {
        // uploaded by single-campus admin
        return schoolList[owner] ? schoolList[owner].name || owner : owner;
      } else {
        if (owner) {
          // uploaded by multiple-campus admin
          return owner.map((campus) => (schoolList[campus] ? schoolList[campus].name || campus : campus)).join(', ');
        } else {
          return 'No Owner';
        }
      }
    }
    return owner;
  };

  isOwner = (assessment) => {
    const { owner } = assessment;
    const { selectedUploader } = this.state;
    if (!selectedUploader) {
      return true;
    }
    if (owner == null || typeof owner === 'string') {
      return owner === selectedUploader;
    }
    if (owner) {
      return owner.includes(selectedUploader);
    }
  };

  canDelete = (owner) => {
    /*
     *  1. District Admins can delete any file uploaded by district or campus admins
     *  2. Single Campus Admins can only delete files that belong to their campus only
     *  3. Multiple Campus Admin can delete files that belong to any of their campuses.
     * */
    const { profile } = this.props;
    if (profile && profile.role === 'districtAdmin') {
      return true;
    }
    if (owner === 'district') {
      return false;
    }
    if (isMultipleCampusAdmin(profile)) {
      const campuses = profile.campusNumber;
      return typeof owner === 'string' ? campuses.includes(owner) : _.isEqual(_.sortBy(owner), _.sortBy(campuses));
    }
    return typeof owner === 'string' && profile.campusNumber === owner;
  };

  // Trigger file input when button is clicked
  handleSDRFilesSelect = () => {
    const { clearUploadSDRResult } = this.props;
    clearUploadSDRResult();
    this.clearSDRFilesState();
    if (this.inputSDRFiles.current) {
      this.inputSDRFiles.current.click();
    }
  };

  // Handle file input change
  handleSDRFilesChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    this.setState({ selectedSDRFiles: files, dialogOpenSDR: true }); // Show dialog with selected files
  };

  // Simulate file upload
  uploadSDRFiles = async (files) => {
    if (files.length === 0) return;
    this.doUploadSDR(files);
  };
  clearSDRFilesState = () => {
    this.setState({ selectedSDRFiles: [], dialogOpenSDR: false });
  };
  handleSDRFilesDialogClose = () => {
    const { clearUploadSDRResult } = this.props;
    clearUploadSDRResult();
    this.clearSDRFilesState();
  };

  renderFilter = () => {
    const { classes, onUploadBtnClick, uploadingSDR, uploadedSDR, uploadSDRFilesResult } = this.props;
    const { selectedSDRFiles, dialogOpenSDR } = this.state;
    return (
      <div className={classes.actionContainer}>
        {this.renderOwnerFilter()}
        <Button variant="contained" color="primary" className={classes.button} onClick={onUploadBtnClick}>
          Upload
          <Icon className={classes.rightIcon}>cloud_upload</Icon>
        </Button>

        {/* Upload button */}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          title="Upload Student Data Request"
          onClick={this.handleSDRFilesSelect}
        >
          Upload SDR Files
          <Icon style={{ marginLeft: '5px' }}>file_upload</Icon>
        </Button>

        {/* Hidden file input */}
        <input
          type="file"
          ref={this.inputSDRFiles}
          style={{ display: 'none' }}
          onChange={this.handleSDRFilesChange}
          multiple // Enable multiple file selection
        />

        {/* Dialog to display selected files */}
        <Dialog open={dialogOpenSDR} onClose={this.handleSDRFilesDialogClose}>
          <DialogTitle>Selected SDR Files {selectedSDRFiles.length} </DialogTitle>
          <DialogContent>
            {selectedSDRFiles.length > 0 ? (
              <List>
                {selectedSDRFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <p>No SDR files selected.</p>
            )}

            <div>
              {uploadSDRFilesResult &&
                uploadSDRFilesResult.fileUrlsSuccess &&
                uploadSDRFilesResult.fileUrlsSuccess.length > 0 && (
                  <div>
                    <p>Files uploaded successfully!</p>
                    <ul>
                      {uploadSDRFilesResult.fileUrlsSuccess.map((fileInfo, index) => (
                        <li key={index}>
                          {fileInfo.fileName} {fileInfo.assessmentType ? fileInfo.assessmentType : ''}(
                          {fileInfo.count ? fileInfo.count : ''})
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              {uploadSDRFilesResult &&
                uploadSDRFilesResult.fileUrlsError &&
                uploadSDRFilesResult.fileUrlsError.length > 0 && (
                  <div>
                    <p>Files error!</p>
                    <ul>
                      {uploadSDRFilesResult.fileUrlsError.map((fileInfo, index) => (
                        <li key={index}>
                          {fileInfo.fileName} ({fileInfo.error ? fileInfo.error : ''})
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSDRFilesDialogClose} color="primary">
              Close
            </Button>
            <Button
              onClick={() => {
                this.uploadSDRFiles(selectedSDRFiles);
                // this.handleSDRFilesDialogClose();
              }}
              color="primary"
              disabled={uploadingSDR || uploadedSDR}
            >
              {uploadingSDR ? 'Uploading...' : uploadedSDR ? 'Uploaded' : 'Upload'}
            </Button>
          </DialogActions>
        </Dialog>

        <Button variant="contained" color="primary" className={classes.button} onClick={this.onInterimTemplateClick}>
          Download Interim Template
          <Icon className={classes.rightIcon}>download</Icon>
        </Button>
      </div>
    );
  };

  handleDelete = (assessment) => () => {
    const { handleDelete, profile } = this.props;
    handleDelete(profile, assessment);
  };

  onSelectFromComputerClick = () => {
    this.dropZoneRef.open();
  };

  onDrop = (accepted, rejected) => {
    const { onDrop, profile } = this.props;
    if (rejected && rejected.length) {
      this.setState({
        errorMsgs: rejected.map((f) => `${f.name} could not be processed`),
      });
    }
    if (accepted && accepted.length) {
      onDrop(accepted, profile);
      if (accepted && accepted.length > 0) {
        const fileNameWithoutExtension = accepted[0].name.split('.')[0];
        this.setState({
          defaultAssessmentName: fileNameWithoutExtension,
        });
      }
    }
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  doUpload = (uploadStaging, fields) => {
    const { profile, doUpload } = this.props;
    doUpload(uploadStaging, fields, profile);
  };
  doUploadSDR = (files) => {
    const { profile, doUploadSDR } = this.props;
    doUploadSDR(files, profile);
  };

  handleDownload = async (assessment) => {
    const { profile, onDownloadClick } = this.props;
    try {
      onDownloadClick(assessment);
    } catch (error) {
      console.error('Error downloading assessment set', error);
    }
  };

  handleReparse = async (assessment) => {
    const { profile, onReparseClick } = this.props;
    try {
      onReparseClick(assessment);
    } catch (error) {
      console.error('Error Reparse assessment set', error);
    }
  };

  handleAssessmentVisibilityBySuperAdminOnlyChange = async (assessment, isChecked) => {
    const { profile, onAssessmentVisibilityBySuperAdminOnlyChange } = this.props;
    try {
      assessment.isVisibleBySuperAdminOnly = isChecked;
      onAssessmentVisibilityBySuperAdminOnlyChange(profile, assessment);
    } catch (error) {
      console.error('Error on change assessment visibility', error);
    }
  };

  doUnrecognizedFileUpload = (uploadStaging, fields) => {
    const { profile, doUpload } = this.props;
    doUpload(uploadStaging, fields, profile, true);
  };

  cancelDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  };

  onInterimTemplateClick = async () => {
    const ref = storage.ref('public/InterimAssessmentTemplate.csv');
    const url = await ref.getDownloadURL();
    window.open(url);
    this.setState({ interimTemplateClicked: true });
  };
  renderUploadModal = () => {
    const { classes, onCancelUploadClick } = this.props;
    return (
      <React.Fragment>
        <Button onClick={onCancelUploadClick}>
          <Icon className={classes.leftIcon}>chevron_left</Icon>
          Back
        </Button>
        <div className={classes.dropzoneWrapper}>
          <Dropzone
            disableClick
            accept={['text/plain', 'application/vnd.ms-excel', 'application/pdf', 'text/csv', '.dat', '.xls', '.xlsx']}
            ref={this.saveDropZoneRef}
            onDrop={this.onDrop}
            multiple={false}
          >
            <div className={classes.dropzone}>
              <div className={classes.dropzoneText}>Drag your file here or</div>
              <Button variant="contained" color="primary" onClick={this.onSelectFromComputerClick}>
                Select from Computer
              </Button>
            </div>
          </Dropzone>
        </div>
      </React.Fragment>
    );
  };

  renderAssessmentsList = () => {
    const { classes, assessmentList, profile } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.headerGrid}>
          <Grid item xs>
            <Typography>Uploaded Assessments</Typography>
          </Grid>
          {this.renderFilter()}
        </Grid>
        {assessmentList.length === 0 ? (
          <Typography className={classes.tableWrapper}>No Assessment Uploaded Yet!</Typography>
        ) : (
          <Paper className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} align="center">
                    Options
                  </TableCell>
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Type</TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Uploaded By
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Processed
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Students Updated
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Students Ignored
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Uploaded At
                  </TableCell>
                  {profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true) && (
                    <TableCell className={classes.tableCell} align="center">
                      Super Admin Only
                    </TableCell>
                  )}
                  {profile && profile.type == 'superAdmin' && (
                    <TableCell className={classes.tableCell} align="center">
                      Download
                    </TableCell>
                  )}
                  {profile && profile.type == 'superAdmin' && (
                    <TableCell className={classes.tableCell} align="center">
                      Reparse
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {assessmentList.filter(this.isOwner).map((assessment) => (
                  <TableRow key={assessment.id}>
                    <TableCell className={classes.tableCell} align="center">
                      {this.deleteIcon(assessment)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography>{assessment.name}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography>{assessment.type}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Typography>{this.renderOwner(assessment.owner)}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {assessment.parsed && assessment.parsed.finished ? (
                        <Icon style={{ color: '#79f5ab' }} className={classes.yesIcon}>
                          check_circle
                        </Icon>
                      ) : (
                        <Icon style={{ color: '#FFFF00' }} className={classes.noIcon}>
                          hourglass_full
                        </Icon>
                      )}
                      {/*access_time*/}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Typography>{assessment.parsed ? assessment.parsed.studentsUpdated || '-' : '-'}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Typography>{assessment.parsed ? assessment.parsed.studentsIgnored || '-' : '-'}</Typography>
                    </TableCell>

                    <TableCell className={classes.tableCell} align="center">
                      <Typography>
                        {assessment &&
                          assessment.file &&
                          assessment.file.uploadedAt &&
                          moment(assessment.file.uploadedAt, 'YYYY-MM-DDTHH:mm:ss[Z]').format('YYYY-MM-DD, HH:mm A')}
                      </Typography>
                    </TableCell>
                    {profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true) && (
                      <TableCell className={classes.tableCell} align="center">
                        <Typography>
                          <Checkbox
                            checked={assessment.isVisibleBySuperAdminOnly == true}
                            onChange={(e) =>
                              this.handleAssessmentVisibilityBySuperAdminOnlyChange(assessment, e.target.checked)
                            }
                            color="primary"
                          />
                        </Typography>
                      </TableCell>
                    )}

                    {profile && profile.type == 'superAdmin' && (
                      <TableCell className={classes.tableCell} align="center">
                        <Typography>
                          <Button
                            size="small"
                            color="primary"
                            className={classes.IconButton}
                            onClick={(e) => this.handleDownload(assessment)}
                          >
                            <Icon className={classes.center}>download</Icon>
                          </Button>
                        </Typography>
                      </TableCell>
                    )}
                    {profile && profile.type == 'superAdmin' && (
                      <TableCell className={classes.tableCell} align="center">
                        <Typography>
                          <Button
                            size="small"
                            color="primary"
                            className={classes.IconButton}
                            onClick={(e) => this.handleReparse(assessment)}
                          >
                            <Icon className={classes.center}>upload</Icon>
                          </Button>
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </React.Fragment>
    );
  };

  getAssessmentTypesSelection = () => Object.keys(OtherAssessmentTypes);

  shouldInit() {
    const { profile, needsInit, addAssessmentsListener, loading } = this.props;
    if (profile && needsInit && !loading) {
      addAssessmentsListener(profile);
    }
  }

  render() {
    const {
      classes,
      loading,
      profile,
      status,
      uploadStaging,
      onCancelUpload,
      uploadProgressMsg,
      uploadOpen,
      assessmentList,
    } = this.props;
    const { errorMsgs, assessmentToBeDeleted, isDeleteModalOpen, defaultAssessmentName } = this.state;

    if (loading || !profile) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <LoadingCircle classes={classes} />
          </div>
        </div>
      );
    }

    // Todo: if not interim upload automatically
    // Todo: if interim or combo ask for name and date

    return (
      <div className={classes.background}>
        <div className={classes.headerDiv}>
          <TitleBackButton title="Assessment Manager" />
        </div>
        <div className={classes.root}>
          <UploadModal
            uploadStaging={uploadStaging}
            uploadProgressMsg={uploadProgressMsg}
            onCancelUpload={onCancelUpload}
            doUpload={this.doUpload}
            doUnrecognizedFileUpload={this.doUnrecognizedFileUpload}
            assessmentTypes={this.getAssessmentTypesSelection()}
            defaultAssessmentName={defaultAssessmentName}
          />
          <DeleteModal
            open={isDeleteModalOpen}
            assessment={assessmentToBeDeleted}
            cancelDelete={this.cancelDelete}
            doDelete={this.handleDelete}
          />
          {uploadOpen ? this.renderUploadModal() : this.renderAssessmentsList()}
          {assessmentList && assessmentList.length !== 0 && (
            <AssessmentGroupManager
              assessmentList={assessmentList}
              canDelete={this.canDelete}
              renderOwner={this.renderOwner}
            />
          )}
          <div>
            {errorMsgs.map((msg, errIndex) => (
              <div key={`error-${errIndex}`}>
                <Typography color="error">{msg}</Typography>
              </div>
            ))}
          </div>
          {status.error && (
            <Typography align="center" variant="subtitle1" color="error" className={classes.messages}>
              {status.error}
            </Typography>
          )}
          {status.message && (
            <Typography align="center" variant="subtitle1" color="primary" className={classes.messages}>
              {status.message}
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AssessmentManager);
