import { asyncStart, asyncFinish, asyncError, UserTypes } from '../../../common';
import { determineFileType } from './assessments.validation2';
import { AssessmentTypes, getDynamicAssessmentModel } from './assessments.types';
import { updateLastInterimTimestamp } from './assessments.db';

const moment = require('moment');

export const GET_ASSESSMENT_LIST = 'assessments.getList';
export const getAssessmentList = (profile) => (dispatch) => {
  dispatch(asyncStart(GET_ASSESSMENT_LIST));
  dispatch({ type: GET_ASSESSMENT_LIST, profile });
};

export const ADD_ASSESSMENTS_LISTENER = 'assessments.addAssessmentsListener';
export const addAssessmentsListener = (profile, id) => (dispatch) => {
  dispatch(asyncStart(ADD_ASSESSMENTS_LISTENER));
  dispatch({
    type: ADD_ASSESSMENTS_LISTENER,
    profile,
    id,
    dispatch,
  });
};

export const REMOVE_ASSESSMENTS_LISTENER = 'assessments.removeAssessmentsListener';
export const removeAssessmentsListener = (profile, id) => (dispatch, getState) => {
  dispatch(asyncStart(REMOVE_ASSESSMENTS_LISTENER));
  const listener = getState().modules.Assessments.assessments.assessmensListeners[id];
  dispatch({
    type: REMOVE_ASSESSMENTS_LISTENER,
    profile,
    listener,
    id,
  });
};

export const ASSESSMENTS_UPDATED = 'assessments.updated';
export const assessmentsUpdated = (assessments) => ({
  type: ASSESSMENTS_UPDATED,
  assessments,
});

export const UPLOAD_ASSESSMENT = 'assessments.upload';
export const uploadAssessment = (uploadStaging, fields, profile, isUnrecognized = false) => (dispatch) => {
  dispatch(asyncStart(UPLOAD_ASSESSMENT));
  dispatch({
    type: UPLOAD_ASSESSMENT,
    ...uploadStaging,
    fields,
    profile,
    dispatch,
    isUnrecognized,
  });
};
export const UPLOAD_SDR_FILES = 'assessments.uploadSDRFiles';
export const uploadSDRFiles = (files, profile) => async (dispatch) => {
  dispatch(asyncStart(UPLOAD_SDR_FILES));
  dispatch({
    type: UPLOAD_SDR_FILES,
    files,
    profile,
  });
};

export const CLEAR_UPLOAD_SDR_RESULT = 'assessments.clearUploadSDRResult';
export const clearUploadSDRResult = () => async (dispatch) => {
  dispatch(asyncStart(CLEAR_UPLOAD_SDR_RESULT));
  dispatch({
    type: CLEAR_UPLOAD_SDR_RESULT,
  });
};

export const DOWNLOAD_ASSESSMENT = 'assessments.download';
export const downloadAssessment = (assessment) => (dispatch) => {
  dispatch(asyncStart(DOWNLOAD_ASSESSMENT));
  dispatch({
    type: DOWNLOAD_ASSESSMENT,
    assessment,
  });
};

export const REPARSE_ASSESSMENT = 'assessments.reparse';
export const reparseAssessment = (assessment) => (dispatch) => {
  dispatch(asyncStart(REPARSE_ASSESSMENT));
  dispatch({
    type: REPARSE_ASSESSMENT,
    assessment,
  });
};

export const DELETE_ASSESSMENT = 'assessments.delete';
export const deleteAssessment = (profile, assessment) => (dispatch) => {
  // console.log('Actions, Delete Assessment');
  dispatch(asyncStart(DELETE_ASSESSMENT));
  dispatch({
    type: DELETE_ASSESSMENT,
    profile,
    assessment,
  });
};

export const VALIDATE_ASSESSMENT_FILE = 'assessments.validateFile';
export const validateAssessmentFile = (file, profile) => async (dispatch) => {
  dispatch(asyncStart(VALIDATE_ASSESSMENT_FILE));
  const assessmentType = await determineFileType(file);
  if (assessmentType) {
    if (assessmentType !== AssessmentTypes.Interim) {
      dispatch(
        asyncFinish(VALIDATE_ASSESSMENT_FILE, {
          file,
          assessmentType,
        })
      );
    }
    if (
      ![AssessmentTypes.Interim, AssessmentTypes.ComboAssessment, , AssessmentTypes.DMACInterim].includes(
        assessmentType
      )
    ) {
      dispatch(
        uploadAssessment({ file, assessmentType }, { name: getDynamicAssessmentModel(assessmentType).name }, profile)
      );
    } else if (assessmentType === AssessmentTypes.Interim) {
      if (profile.userType === UserTypes.firstClass) {
        dispatch(
          asyncFinish(VALIDATE_ASSESSMENT_FILE, {
            file,
            assessmentType,
          })
        );
      } else if (profile.userType === UserTypes.free) {
        dispatch(
          asyncError(
            VALIDATE_ASSESSMENT_FILE,
            'validationError',
            new Error('Free accounts can not upload interim files!'),
            {
              file,
            }
          )
        );
      } else if (profile.userType === UserTypes.businessClass) {
        const now = moment();
        if (!profile.lastInterimTimestamp || now.isAfter(moment(profile.lastInterimTimestamp, 'x').add(1, 'year'))) {
          await updateLastInterimTimestamp(profile, now.format('x'));
          dispatch(
            asyncFinish(VALIDATE_ASSESSMENT_FILE, {
              file,
              assessmentType,
            })
          );
        } else {
          dispatch(
            asyncError(
              VALIDATE_ASSESSMENT_FILE,
              'validationError',
              new Error('Business class accounts can upload only one Interim Assessment in a year!'),
              {
                file,
              }
            )
          );
        }
      }
    }
  } else {
    // dispatch(asyncError(VALIDATE_ASSESSMENT_FILE, 'validationError', new Error('Unrecognized file type'), {
    //   file,
    // }));
    dispatch(
      asyncFinish(VALIDATE_ASSESSMENT_FILE, {
        file,
        assessmentType,
      })
    );
  }
};

export const GET_ASSESSMENT_GROUPS = 'assessments.getAssessmentGroups';
export const getAssessmentGroups = (profile) => (dispatch) => {
  dispatch(asyncStart(GET_ASSESSMENT_GROUPS));
  dispatch({ type: GET_ASSESSMENT_GROUPS, profile });
};

export const DELETE_ASSESSMENT_GROUP = 'assessments.deleteAssessmentGroup';
export const deleteAssessmentGroup = (profile, assessmentGroupId) => (dispatch) => {
  const orgId = profile.organizationId;
  dispatch(asyncStart(DELETE_ASSESSMENT_GROUP));
  dispatch({
    type: DELETE_ASSESSMENT_GROUP,
    orgId,
    assessmentGroupId,
  });
};

export const ADD_ASSESSMENT_GROUP = 'assessments.addAssessmentGroup';
export const addAssessmentGroup = (profile, assessmentGroup) => (dispatch) => {
  dispatch(asyncStart(ADD_ASSESSMENT_GROUP));
  dispatch({
    type: ADD_ASSESSMENT_GROUP,
    profile,
    assessmentGroup,
  });
};

export const UPDATE_ASSESSMENT_GROUP = 'assessments.updateAssessmentGroup';
export const updateAssessmentGroup = (profile, assessmentGroupId, assessmentGroup) => (dispatch) => {
  dispatch(asyncStart(UPDATE_ASSESSMENT_GROUP));
  dispatch({
    type: UPDATE_ASSESSMENT_GROUP,
    profile,
    assessmentGroupId,
    assessmentGroup,
  });
};

export const CHANGE_ASSESSMENT_VISIBILITY = 'assessments.changeAssessmentVisibility';
export const changeAssessmentVisibility = (profile, assessment) => (dispatch) => {
  dispatch(asyncStart(CHANGE_ASSESSMENT_VISIBILITY));
  dispatch({
    type: CHANGE_ASSESSMENT_VISIBILITY,
    profile,
    assessment,
  });
};

export const CHANGE_ASSESSMENT_GROUP_VISIBILITY = 'assessments.changeAssessmentGroupVisibility';
export const changeAssessmentGroupVisibility = (profile, assessmentGroup) => (dispatch) => {
  dispatch(asyncStart(CHANGE_ASSESSMENT_GROUP_VISIBILITY));
  dispatch({
    type: CHANGE_ASSESSMENT_GROUP_VISIBILITY,
    profile,
    assessmentGroup,
  });
};
