import { db } from '../../../App/+store/firebase';
import { getMultipleCampusData, isMultipleCampusAdmin } from '../../../common/utils';

import { getCurrentYear, getPriorYear } from '../../../common/+store/utils';

const dbGet = (path) =>
  db
    .ref(path)
    .once('value')
    .then((s) => s.val());

export const getGradeSpanTable = async () =>
  db
    .ref('newDatabase/dataStructure/constants/gradeSpanTable')
    .once('value')
    .then((snapshot) => snapshot.val());

export const getSchools = async (profile) => {
  const orgId = profile.organizationId;
  // Todo: make this an api call for the sake of role-based security
  // Get assessmentSets
  if (isMultipleCampusAdmin(profile)) {
    return getMultipleCampusData(profile, `newDatabase/organizations/${orgId}/schools`, false);
  }
  if (profile.role === 'campusAdmin') {
    const schoolId = profile.campusNumber;
    const school = await dbGet(`newDatabase/organizations/${orgId}/schools/${schoolId}`);
    return { [schoolId]: school };
  }
  if (profile.role === 'districtAdmin') {
    const schoolList = await dbGet(`newDatabase/organizations/${orgId}/schools`);
    const district = await dbGet(`newDatabase/organizations/${orgId}/district`);
    return {
      ...schoolList,
      [profile.districtNumber]: district,
    };
  }
  return {};
};

export const updateSettings = async (profile, schoolId, customSettings) => {
  if (profile && profile.isSuperAdmin == true) {
    const orgId = profile.organizationId;
    const currentYear = getCurrentYear();
    try {
      if (schoolId.length === 6) {
        return await db.ref(`newDatabase/organizations/${orgId}/district`).update({
          ...customSettings,
        });
      }
      await db.ref(`newDatabase/organizations/${orgId}/schools/${schoolId}`).update({
        ...customSettings,
      });
      const lowestPerformingSubgroupsPriorYearList = [];

      if (
        customSettings.firstLowestPerformingSubgroupsPriorYear &&
        customSettings.firstLowestPerformingSubgroupsPriorYear.length > 0
      ) {
        lowestPerformingSubgroupsPriorYearList.push(customSettings.firstLowestPerformingSubgroupsPriorYear);
      }
      if (
        customSettings.secondLowestPerformingSubgroupsPriorYear &&
        customSettings.secondLowestPerformingSubgroupsPriorYear.length > 0
      ) {
        lowestPerformingSubgroupsPriorYearList.push(customSettings.secondLowestPerformingSubgroupsPriorYear);
      }

      if (lowestPerformingSubgroupsPriorYearList.length > 0) {
        await db
          .ref(`newDatabase/dataStructure/constants/lowestPerformingSubgroupsPriorYear/${currentYear}/${schoolId}`)
          .set(lowestPerformingSubgroupsPriorYearList);
      }
    } catch (err) {
      console.error('Error: ', err);
    }
  } else {
    throw 'You do not have permissions to update district or schools settings';
  }
};

export const dbGetDefaultSettings = async (profile, schoolIds) => {
  const orgId = profile.organizationId;
  const schoolList = {};
  await Promise.all(
    schoolIds.map(async (schoolId) => {
      if (schoolId.length === 9) {
        const school = await dbGet(`newDatabase/organizations/${orgId}/schools/${schoolId}`);
        schoolList[schoolId] = school;
      }
    })
  );
  if (profile.role === 'districtAdmin') {
    const districtId = profile.districtNumber;
    schoolList[districtId] = await dbGet(`newDatabase/organizations/${orgId}/district`);
  }
  return schoolList;
};

export default 'SchoolSettings.db.js';
