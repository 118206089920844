import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Button, Icon, TextField, MenuItem } from '@material-ui/core';

const getGradeLetter = (score) => {
  const mapping = {
    100: 'A',
    90: 'A',
    80: 'B',
    70: 'C',
    60: 'D',
  };
  const rounded = Math.floor(score / 10) * 10;
  return mapping[rounded] || 'F';
};

const groupingTypes = {
  all: 'All',
  subject: 'Subject',
  teacher: 'Teacher',
  grade: 'Grade',
  studentGroup: 'Student Group',
};

class DesiredAFScore extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    desiredValue: PropTypes.number.isRequired,
    updateDesired: PropTypes.func.isRequired,
    currentAF: PropTypes.number.isRequired,
    maxAF: PropTypes.number.isRequired,
    groupByValue: PropTypes.any,
    storeGroupBy: PropTypes.func.isRequired,
  };

  incrementDesired = () => {
    const { desiredValue, updateDesired, maxAF } = this.props;
    if (desiredValue < maxAF) {
      updateDesired(desiredValue + 1);
    }
  };

  decrementDesired = () => {
    const { desiredValue, updateDesired, currentAF } = this.props;
    if (desiredValue > currentAF) {
      updateDesired(desiredValue - 1);
    }
  };

  handleChangeGroupBy = (event) => {
    const { storeGroupBy } = this.props;
    storeGroupBy(event.target.value);
  };

  componentDidMount() {
    const { currentAF, desiredValue, updateDesired, maxAF } = this.props;
    // Update the desired value on the first load
    updateDesired(Math.min(maxAF, desiredValue));
  }

  render() {
    const { classes, desiredValue, currentAF, maxAF, groupByValue } = this.props;

    return (
      <form className={classes.flightPlanOptions}>
        <FormControl className={classes.formControl}>
          <div className={classes.smallerTextField}>
            <div className={classes.labelText}>Current A-F Score</div>
            <div className={classes.valueText}>{`${currentAF} (${getGradeLetter(currentAF)} Rating)`}</div>
          </div>
          <div className={classes.smallerTextField}>
            <div className={classes.labelText}>Desired A-F Score</div>
            <div className={classes.valueText}>{`${desiredValue} (${getGradeLetter(desiredValue)} Rating)`}</div>
            <div className={classes.helperText}>{`${maxAF} is the highest score possible with the current data.`}</div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Button size="small" color="primary" className={classes.button} onClick={this.decrementDesired}>
              <Icon>remove</Icon>
            </Button>
            <Button size="small" color="primary" className={classes.button} onClick={this.incrementDesired}>
              <Icon>add</Icon>
            </Button>
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            select
            label="Group By"
            className={classes.textField}
            value={groupByValue || ''}
            onChange={this.handleChangeGroupBy}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            helperText="Grouping"
            margin="normal"
          >
            {Object.entries(groupingTypes).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </form>
    );
  }
}

export default DesiredAFScore;
