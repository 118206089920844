import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableBody } from '@material-ui/core';
import { CustomTableRow, CustomTableCell, RowLabelCell } from '../Common/CustomTableComponents';
import EmptyCols from '../Common/EmptyCols';
import { Indicators } from '../../+store/aToF';
import { d3Indicators } from '../Common/utils';
import { getValue } from '../../Common/utils';
import TotalIndicator from '../Common/TotalIndicator';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    // background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(249, 128, 29, 0.6);',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F9801D;',
  },
  body: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F9801D;',
  },
  footer: {
    // background: '#C4C4C4;',
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  circle: {
    margin: '10px',
    display: 'inline-block',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: '2px solid white',
    background: 'white',
  },
  circle_0: {
    border: '2px solid red',
  },
  circle_1: {
    backgroundImage:
      'linear-gradient(00deg,white 50%, transparent 50%),linear-gradient(90deg, red 50%, transparent 50%)',
  },
  circle_2: {
    backgroundImage:
      'linear-gradient(-90deg, transparent  50%, transparent 50%),linear-gradient(90deg, yellow 50%, transparent 50%);',
  },
  circle_3: {
    backgroundImage:
      'linear-gradient(180deg, transparent 50%, yellow 50%), linear-gradient(90deg, yellow 50%, transparent 50%);',
  },
  circle_4: {
    background: 'green',
  },
  circle_Number: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

const indicatorsLength = Object.keys(Indicators).length;

const totalsColumns = {
  pointsEarned: 'Component Points Earned',
  PointsPossible: 'Component Points Possible',
  percentScore: 'Component Percent Score',
};
const totalsColumnsLength = Object.keys(totalsColumns).length;

const scoreColumns = {
  weight: 'Weight',
  score: 'Score',
};
const scoreColumnsLength = Object.keys(scoreColumns).length;

const RowComponent = ({ label, component, content, style }) => (
  <CustomTableRow>
    <RowLabelCell>{label}</RowLabelCell>
    {Object.entries(d3Indicators).map(([key]) =>
      component.eligible[key] ? (
        <CustomTableCell key={key} style={style(component, key)}>
          {content(component, key)}
        </CustomTableCell>
      ) : (
        <CustomTableCell key={key}>-</CustomTableCell>
      )
    )}
    <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
  </CustomTableRow>
);
RowComponent.propTypes = {
  label: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  content: PropTypes.func.isRequired,
  style: PropTypes.func.isRequired,
};

const getTargetValue = (eligibles, metTargets, indicatorKey, metIndex, showAllTargets) => {
  if (eligibles && eligibles[indicatorKey]) {
    let metKey = `t${metIndex}`;
    if (
      metTargets &&
      metTargets[indicatorKey] &&
      metTargets[indicatorKey].targets &&
      metTargets[indicatorKey].targets[metKey]
    ) {
      var target = metTargets[indicatorKey].targets[metKey];
      var isValidTarget = isValidTargetValue(metTargets[indicatorKey].targets, metIndex);
      if (showAllTargets == true || isValidTarget) {
        if (!isNaN(target)) {
          return target;
        }
      }
    }
  }
  return '-';
};

const isValidTargetValue = (targets, metIndex) => {
  for (let point = metIndex + 1; point < 5; point++) {
    if (targets[`t${metIndex}`] > targets[`t${point}`]) {
      return false;
    }
  }
  return true;
};

const SchoolQuality = ({ classes, component, schoolTypeConversion, showAllTargets }) => {
  if (!component) return null;
  return (
    <Table className={classes.root}>
      <TableHead>
        <CustomTableRow>
          <CustomTableCell />
          {Object.entries(Indicators).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
          {Object.entries(totalsColumns).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
          {Object.entries(scoreColumns).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
        </CustomTableRow>
      </TableHead>
      <TableBody>
        <CustomTableRow>
          <RowLabelCell>School Quality</RowLabelCell>
          <EmptyCols length={indicatorsLength + totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>

        <CustomTableRow>
          <RowLabelCell>{`Included`}</RowLabelCell>
          {Object.entries(Indicators).map(([key]) => (
            <CustomTableCell key={key}>
              {component.eligible &&
              component.eligible[key] &&
              component.componentIndicators &&
              component.componentIndicators.indexOf(key) >= 0
                ? 'Y'
                : '-'}
            </CustomTableCell>
          ))}
          <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>
        <RowComponent
          label="% Approaches"
          component={component}
          content={(com, key) => `${getValue(com, 'approaches', key)}%`}
          style={() => ({})}
        />
        <RowComponent
          label="% Meets"
          component={component}
          content={(com, key) => `${getValue(com, 'meets', key)}%`}
          style={() => ({})}
        />
        <RowComponent
          label="% Masters"
          component={component}
          content={(com, key) => `${getValue(com, 'masters', key)}%`}
          style={() => ({})}
        />
        <RowComponent
          label="Total Tests"
          component={component}
          content={(com, key) => getValue(com, 'denominator', key)}
          style={() => ({})}
        />

        <RowComponent
          label="Score"
          component={component}
          content={(com, key) => `${getValue(com, 'rate', key)}%`}
          style={() => ({})}
        />

        <CustomTableRow>
          <RowLabelCell>{`4 Points`}</RowLabelCell>
          {Object.entries(Indicators).map(([key]) => (
            <CustomTableCell key={key}>
              {getTargetValue(component.eligible, component.metTarget, key, 4, showAllTargets)}
            </CustomTableCell>
          ))}
          <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>
        <CustomTableRow>
          <RowLabelCell>{`3 Points`}</RowLabelCell>
          {Object.entries(Indicators).map(([key]) => (
            <CustomTableCell key={key}>
              {getTargetValue(component.eligible, component.metTarget, key, 3, showAllTargets)}
            </CustomTableCell>
          ))}
          <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>

        <CustomTableRow>
          <RowLabelCell>{`2 Points`}</RowLabelCell>
          {Object.entries(Indicators).map(([key]) => (
            <CustomTableCell key={key}>
              {getTargetValue(component.eligible, component.metTarget, key, 2, showAllTargets)}
            </CustomTableCell>
          ))}
          <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>

        <CustomTableRow>
          <RowLabelCell>{`1 Point`}</RowLabelCell>
          {Object.entries(Indicators).map(([key]) => (
            <CustomTableCell key={key}>
              {getTargetValue(component.eligible, component.metTarget, key, 1, showAllTargets)}
            </CustomTableCell>
          ))}
          <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>

        <CustomTableRow>
          <RowLabelCell className={classes.boldCell}>{`Earned`}</RowLabelCell>
          {Object.entries(Indicators).map(([key]) => (
            <CustomTableCell key={key} className={classes.boldCell}>
              {component.eligible && component.eligible[key] ? (
                <div className={classNames(classes.circle, classes['circle_' + component.metTarget[key].point])}>
                  <div className={classes.circle_Number}>{component.metTarget[key].point} </div>
                </div>
              ) : (
                '-'
              )}
            </CustomTableCell>
          ))}
          <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>
      </TableBody>
      <TotalIndicator
        footerClass={classes.footer}
        title="School Quality Total"
        indicatorsLength={indicatorsLength}
        totalIndicators={component.totalIndicators}
      />
    </Table>
  );
};
SchoolQuality.propTypes = {
  component: PropTypes.object,
  classes: PropTypes.any,
  showAllTargets: PropTypes.bool,
};

export default withStyles(styles)(SchoolQuality);
