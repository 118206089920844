import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableBody } from '@material-ui/core';
import { CustomTableRow, CustomTableCell, RowLabelCell } from '../Common/CustomTableComponents';
import EmptyCols from '../Common/EmptyCols';
import SubComponentRows from '../Common/SubComponentRows';
import { d3Indicators } from '../Common/utils';
import { d3EmptyIndicators } from '../Common/utils';
import TotalIndicator from '../Common/TotalIndicator';

const styles = (theme) => ({
  root: {
    // background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(146, 200, 62, 0.6);',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #92C83E;',
  },
  body: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #92C83E;',
  },
  footer: {
    // background: '#C4C4C4;',
  },
});

const indicatorsLength = Object.keys(d3Indicators).length;

const totalsColumns = {
  pointsEarned: 'Component Points Earned',
  PointsPossible: 'Component Points Possible',
  percentScore: 'Component Percent Score',
};
const totalsColumnsLength = Object.keys(totalsColumns).length;

const scoreColumns = {
  weight: 'Weight',
  score: 'Score',
};
const scoreColumnsLength = Object.keys(scoreColumns).length;
//   studentsListPath={studentsListPath + '/reading/meets/all'}
//   studentsListPath={studentsListPath + '/math/meets/all'}
const AcademicGrowth = ({
  classes,
  component,
  handleGetStudentsList,
  studentsListPath,
  profile,
  schoolTypeConversion,
  showAllTargets,
  d2aTables,
}) => {
  if (!component) return null;
  return (
    <Table className={classes.root}>
      <TableHead>
        <CustomTableRow>
          <CustomTableCell />
          {Object.entries(d3Indicators).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
          {Object.entries(totalsColumns).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
          {Object.entries(scoreColumns).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
        </CustomTableRow>
      </TableHead>
      <TableBody>
        <CustomTableRow>
          <RowLabelCell>Academic Growth - Reading</RowLabelCell>
          <EmptyCols length={indicatorsLength + totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>
        <SubComponentRows
          componentCode="academicGrowth"
          label="Reading Growth"
          totalsColumnsLength={totalsColumnsLength}
          scoreColumnsLength={scoreColumnsLength}
          counts={component.reading.numerator}
          totals={component.reading.denominator}
          percents={component.reading.rate || d3EmptyIndicators}
          targets={component.reading.targets}
          totalIndicators={component.reading.totalIndicators}
          eligibles={component.reading.eligible || d3EmptyIndicators}
          metTargets={component.reading.metTarget || d3EmptyIndicators}
          handleGetStudentsList={handleGetStudentsList}
          profile={profile}
          schoolTypeConversion={schoolTypeConversion}
          studentsListPath={studentsListPath ? studentsListPath + '/reading/meetsPoints' : null}
          studentsListPathDoesNotMeet={studentsListPath ? studentsListPath + '/reading/limitedProgress' : null}
          componentIndicators={component.componentIndicators}
          showAllTargets={showAllTargets}
          d2aTables={d2aTables ? d2aTables['reading'] : null}
        />
        <CustomTableRow>
          <RowLabelCell>Academic Growth - Math</RowLabelCell>
          <EmptyCols length={indicatorsLength + totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>
        <SubComponentRows
          componentCode="academicGrowth"
          label="Math Growth"
          totalsColumnsLength={totalsColumnsLength}
          scoreColumnsLength={scoreColumnsLength}
          counts={component.math.numerator}
          totals={component.math.denominator}
          percents={component.math.rate || d3EmptyIndicators}
          targets={component.math.targets}
          totalIndicators={component.math.totalIndicators}
          eligibles={component.math.eligible || d3EmptyIndicators}
          metTargets={component.math.metTarget || d3EmptyIndicators}
          handleGetStudentsList={handleGetStudentsList}
          profile={profile}
          schoolTypeConversion={schoolTypeConversion}
          studentsListPath={studentsListPath ? studentsListPath + '/math/meetsPoints' : null}
          studentsListPathDoesNotMeet={studentsListPath ? studentsListPath + '/math/limitedProgress' : null}
          componentIndicators={component.componentIndicators}
          showAllTargets={showAllTargets}
          d2aTables={d2aTables ? d2aTables['math'] : null}
        />
      </TableBody>
      <TotalIndicator
        footerClass={classes.footer}
        title="Growth Total"
        indicatorsLength={indicatorsLength}
        totalIndicators={component.totalIndicators}
      />
    </Table>
  );
};
AcademicGrowth.propTypes = {
  d2aTables: PropTypes.object,
  component: PropTypes.object,
  classes: PropTypes.any,
  handleGetStudentsList: PropTypes.func,
  studentsListPath: PropTypes.string,
  profile: PropTypes.object,
  schoolTypeConversion: PropTypes.string,
  showAllTargets: PropTypes.bool,
};

export default withStyles(styles)(AcademicGrowth);
