import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CustomTableRow, RowLabelCell, CustomTableCell } from '../CustomTableComponents';
import EmptyCols from '../EmptyCols';
import { Indicators } from '../../../+store/aToF';
import { formatNumberOrDash } from '../utils';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => ({
  metTarget: {
    backgroundColor: '#92FFBE',
  },
  didNotMeet: {
    backgroundColor: '#FF5656',
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
  },

  circle: {
    margin: '10px',
    display: 'inline-block',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: '2px solid white',
    background: 'white',
  },
  circle_0: {
    border: '2px solid red',
  },
  circle_1: {
    backgroundImage:
      'linear-gradient(00deg,white 50%, transparent 50%),linear-gradient(90deg, red 50%, transparent 50%)',
  },
  circle_2: {
    backgroundImage:
      'linear-gradient(-90deg, transparent  50%, transparent 50%),linear-gradient(90deg, yellow 50%, transparent 50%);',
  },
  circle_3: {
    backgroundImage:
      'linear-gradient(180deg, transparent 50%, yellow 50%), linear-gradient(90deg, yellow 50%, transparent 50%);',
  },
  circle_4: {
    background: 'green',
  },
  circle_Number: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});
const getTargetValue = (eligibles, metTargets, indicatorKey, metIndex, showAllTargets) => {
  if (eligibles && eligibles[indicatorKey]) {
    let metKey = `t${metIndex}`;
    if (
      metTargets &&
      metTargets[indicatorKey] &&
      metTargets[indicatorKey].targets &&
      metTargets[indicatorKey].targets[metKey]
    ) {
      var target = metTargets[indicatorKey].targets[metKey];
      var isValidTarget = isValidTargetValue(metTargets[indicatorKey].targets, metIndex);
      if (showAllTargets == true || isValidTarget) {
        if (!isNaN(target)) {
          return target;
        }
      }
    }
  }
  return '-';
};

const isValidTargetValue = (targets, metIndex) => {
  for (let point = metIndex + 1; point < 5; point++) {
    if (targets[`t${metIndex}`] > targets[`t${point}`]) {
      return false;
    }
  }
  return true;
};
const getAcademicGrowthValue = (d2aTables, key) => {
  return d2aTables && d2aTables.count && d2aTables.count.zeroPoint ? `${d2aTables.count.zeroPoint[key]}` : '-';
};

const getAcademicAchievementValue = (totals, counts, key) => {
  return totals[key] > 0 ? `${totals[key] - counts[key]}` : '-';
};
const SubComponentRows = ({
  componentCode,
  label,
  classes,
  totalsColumnsLength,
  scoreColumnsLength,
  counts,
  totals,
  percents,
  targets,
  totalIndicators,
  eligibles,
  metTargets,
  handleGetStudentsList,
  studentsListPath,
  profile,
  schoolTypeConversion,
  componentIndicators,
  studentsListPathDoesNotMeet,
  showAllTargets,
  d2aTables,
}) => (
  <React.Fragment>
    <CustomTableRow>
      <RowLabelCell>{`Included`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell key={key}>
          {eligibles && eligibles[key] && componentIndicators && componentIndicators.indexOf(key) >= 0 ? 'Y' : '-'}
        </CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>

    <CustomTableRow>
      <RowLabelCell>{componentCode == 'academicGrowth' ? `# Full Point and Half Point` : '# Met'}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) =>
        studentsListPath ? (
          <CustomTableCell key={key}>
            <Button
              className={classes.clickableCell}
              color="inherit"
              onClick={() => handleGetStudentsList(profile.organizationId, `${studentsListPath}/${key}`)}
              disabled={!counts[key]}
            >
              {counts[key] > 0 ? `${counts[key]}` : '-'}
            </Button>
          </CustomTableCell>
        ) : (
          <CustomTableCell key={key}> {counts[key] > 0 ? `${counts[key]}` : '-'} </CustomTableCell>
        )
      )}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>
    {studentsListPathDoesNotMeet && (
      <CustomTableRow>
        <RowLabelCell>{componentCode == 'academicGrowth' ? `# Zero Point` : '# Not Met'}</RowLabelCell>
        {Object.entries(Indicators).map(([key]) =>
          studentsListPath ? (
            <CustomTableCell key={key}>
              <Button
                className={classes.clickableCell}
                color="inherit"
                onClick={() => handleGetStudentsList(profile.organizationId, `${studentsListPathDoesNotMeet}/${key}`)}
                disabled={!counts[key]}
              >
                {componentCode == 'academicGrowth'
                  ? getAcademicGrowthValue(d2aTables, key)
                  : getAcademicAchievementValue(totals, counts, key)}
              </Button>
            </CustomTableCell>
          ) : (
            <CustomTableCell key={key}>
              {componentCode == 'academicGrowth'
                ? getAcademicGrowthValue(d2aTables, key)
                : getAcademicAchievementValue(totals, counts, key)}
            </CustomTableCell>
          )
        )}
        <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
      </CustomTableRow>
    )}
    <CustomTableRow>
      <RowLabelCell>{`# Total`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell key={key}> {totals[key] > 0 ? `${totals[key]}` : '-'} </CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>
    <CustomTableRow>
      <RowLabelCell className={classes.boldCell}>{`% Met`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell className={classes.boldCell} key={key}>
          {`${formatNumberOrDash(percents[key], '%')}`}
        </CustomTableCell>
      ))}
      <CustomTableCell>{formatNumberOrDash(totalIndicators.percent, '%')}</CustomTableCell>
      <EmptyCols length={scoreColumnsLength + scoreColumnsLength} />
    </CustomTableRow>

    <CustomTableRow>
      <RowLabelCell>{`4 Points`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell key={key}>{getTargetValue(eligibles, metTargets, key, 4, showAllTargets)}</CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>
    <CustomTableRow>
      <RowLabelCell>{`3 Points`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell key={key}>{getTargetValue(eligibles, metTargets, key, 3, showAllTargets)}</CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>

    <CustomTableRow>
      <RowLabelCell>{`2 Points`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell key={key}>{getTargetValue(eligibles, metTargets, key, 2, showAllTargets)}</CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>

    <CustomTableRow>
      <RowLabelCell>{`1 Point`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell key={key}>{getTargetValue(eligibles, metTargets, key, 1, showAllTargets)}</CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>

    <CustomTableRow>
      <RowLabelCell className={classes.boldCell}>{`Earned`}</RowLabelCell>
      {Object.entries(Indicators).map(([key]) => (
        <CustomTableCell className={classes.boldCell} key={key}>
          {eligibles && eligibles[key] ? (
            <div className={classNames(classes.circle, classes['circle_' + metTargets[key].point])}>
              <div className={classes.circle_Number}>{metTargets[key].point} </div>
            </div>
          ) : (
            '-'
          )}
        </CustomTableCell>
      ))}
      <EmptyCols length={totalsColumnsLength + scoreColumnsLength} />
    </CustomTableRow>
  </React.Fragment>
);
SubComponentRows.propTypes = {
  label: PropTypes.string.isRequired,
  counts: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  percents: PropTypes.object.isRequired,
  targets: PropTypes.object.isRequired,
  totalIndicators: PropTypes.object.isRequired,
  eligibles: PropTypes.object.isRequired,
  metTargets: PropTypes.object.isRequired,
  classes: PropTypes.any,
  totalsColumnsLength: PropTypes.number.isRequired,
  scoreColumnsLength: PropTypes.number.isRequired,
  handleGetStudentsList: PropTypes.func,
  studentsListPath: PropTypes.string,
  studentsListPathDoesNotMeet: PropTypes.string,
  profile: PropTypes.object,
  schoolTypeConversion: PropTypes.string,
  showAllTargets: PropTypes.bool,
  d2aTables: PropTypes.object,
};

export default withStyles(styles)(SubComponentRows);
