import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, TextField, MenuItem } from '@material-ui/core';
import { saveAs } from 'file-saver';
import { LoadingCircle, TitleBackButton, ExportCSVButton, PrintButton, UserTypes } from '../../common';
import AtoFFilters from '../AtoFFilters';
import D1Table from '../D1Table';
import { ReportSections, ReportSectionKeys, ReportSectionsForFreeUsers, JobNames } from '../+store/aToF';
import D2aTable from '../D2aTable';
import D2bTable from '../D2bTable';
import D3 from '../D3';
import D1GradRate from '../D1GradRate';
import D1Ccmr from '../D1Ccmr';
import SectionSummary from '../SectionSummary';
import Summary from '../Summary';
import SummaryDetails from '../SummaryDetails';
import CampusesWeights from '../CampusesWeights';
import StudentsList from '../Common/StudentsList';
import { serializeWithStyles, copyComputedStyle } from './serializeWithStyles';
import exportAsCsv from './exportAsCsv';

const styles = (theme) => ({
  root: {
    padding: '16px 24px',
    paddingBottom: 180,
  },
  content: {
    margin: 'auto',
  },
  progress: {},
  textField: {
    minWidth: '280px',
    marginRight: 8,
  },
  formControl: {},
  header: {
    display: 'flex',
    marginBottom: 24,
  },
  subheader: {
    display: 'flex',
    justifyContent: 'center',
  },
});

// const tempData = [
//   {
//     text: 'Hey my name is guy',
//     icon: 'check',
//   },
//   {
//     text: 'Hey my name is BALL',
//     icon: 'times',
//   },
// ];

class AtoFBase extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string,
    profile: PropTypes.object,
    needsInit: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    status: PropTypes.shape({
      error: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    assessmentList: PropTypes.array.isRequired,
    getSchools: PropTypes.func.isRequired,
    reportData: PropTypes.object,
    getAssessmentGroups: PropTypes.func.isRequired,
    assessmentGroups: PropTypes.object.isRequired,
    getStudentRoster: PropTypes.func,
    students: PropTypes.object,
    avatars: PropTypes.object,
    getAvatars: PropTypes.func,
    handleGetStudentsList: PropTypes.func,
  };

  state = {
    reportSection: ReportSectionKeys.summary,
    // printing: false,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  handleChange = (name) => (event) => {
    switch (name) {
      case 'reportSection':
        this.setState({ reportSection: event.target.value });
        break;
      default:
    }
  };

  changeReportSection = (name) => {
    this.setState({ reportSection: name });
  };

  exportCsv = (reportSection) => async () => {
    const { reportData, filters, schoolList } = this.props;
    const districtCampusName = schoolList[filters.school] ? schoolList[filters.school].name : filters.school;
    const { blob, fileName } = await exportAsCsv(reportSection, reportData, districtCampusName);
    if (blob) {
      saveAs(blob, fileName);
    }
  };

  exportPdf = (reportSection) => () => {
    const { filters, schoolList } = this.props;
    const districtCampusName = schoolList[filters.school] ? schoolList[filters.school].name : filters.school;
    const summaryElement = document.getElementById('reportSectionSummary');
    const summaryContent = summaryElement != null ? summaryElement.cloneNode(true) : null;
    const title = document.createElement('H3');
    const titles = {
      [ReportSectionKeys.summary]: { title: 'Summary Report', elementId: 'reportSummary' },
      [ReportSectionKeys.summaryDetails]: { title: 'Summary Report Details', elementId: 'reportSummaryDetails' },
      [ReportSectionKeys.d1staar]: { title: 'Student Achievement: STAAR Performance', elementId: 'reportD1' },
      [ReportSectionKeys.d1ccmr]: {
        title: 'Student Achievement: College, Career and Military Readiness',
        elementId: 'reportCCMR',
      },
      [ReportSectionKeys.d1gradRate]: { title: 'Student Achievement: Graduation Rate', elementId: 'reportGrad' },
      [ReportSectionKeys.d2a]: { title: 'School Progress: Academic Growth', elementId: 'reportD2a' },
      [ReportSectionKeys.d2b]: { title: 'School Progress: Relative Performance', elementId: 'reportD2b' },
      [ReportSectionKeys.d3]: { title: 'Closing Gaps', elementId: 'reportD3' },
      [ReportSectionKeys.campusesWeights]: { title: 'Campuses Weights', elementId: 'reportCampusesWeights' },
    };
    if (titles[reportSection]) {
      const element = document.getElementById(titles[reportSection].elementId);
      const textTitle = `${districtCampusName} - ${titles[reportSection].title}`;
      const content = element.cloneNode(true);
      copyComputedStyle(element, content, true);
      title.append(document.createTextNode(textTitle));
      if (summaryContent) {
        content.prepend(summaryContent);
        copyComputedStyle(document.getElementById('reportSectionSummary').cloneNode(true), summaryContent);
      }
      content.prepend(title);
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      let contectHtml = serializeWithStyles(content);
      let headHtml = '';
      if (document.getElementsByTagName('head') && document.getElementsByTagName('head').length > 0) {
        let headTag = document.getElementsByTagName('head')[0];
        let arrHeadStyles = headTag.getElementsByTagName('style');
        if (arrHeadStyles && arrHeadStyles.length > 0) {
          for (let i = 0; i < arrHeadStyles.length; i++) {
            headHtml += arrHeadStyles[i].outerHTML;
          }
        }
      }
      var htmlDoc = '';
      htmlDoc += headHtml;
      htmlDoc += ' ';
      htmlDoc += contectHtml;

      pri.document.write(htmlDoc);
      pri.document.close();
      pri.focus();
      pri.print();
      if (summaryContent) {
        content.removeChild(summaryContent);
      }
      content.removeChild(title);
    }
  };

  shouldInit() {
    const {
      profile,
      needsInit,
      init,
      loading,
      getSchools,
      getAssessmentGroups,
      students,
      getStudentRoster,
      avatars,
      getAvatars,
    } = this.props;
    if (profile && needsInit && !loading) {
      init(profile);
      getSchools(profile);
      getAssessmentGroups(profile);
      if (!Object.keys(students).length) {
        getStudentRoster(profile);
      }
      if (!avatars) {
        getAvatars();
      }
    }
  }

  render() {
    const {
      classes,
      loading,
      profile,
      assessmentList,
      reportData,
      assessmentGroups,
      loadingMsg,
      handleGetStudentsList,
    } = this.props;
    const { reportSection } = this.state;

    // free user type can only see summary section
    let reportSections;
    if (profile) {
      reportSections = profile.userType === UserTypes.free ? ReportSectionsForFreeUsers : ReportSections;
    }

    let report = reportData ? reportData.report : null;
    let allCampusReports = reportData ? reportData.allCampusReports : [];

    let sectionComponent = null;
    let sectionComponentCampusInDistrict = null;
    let sectionSummaryComponent = null;
    if (report) {
      switch (reportSection) {
        case ReportSectionKeys.summary:
          if (report.summary) {
            sectionComponent = (
              <Summary
                report={report}
                onChangeReportSection={this.changeReportSection}
                isFreeAccount={profile.userType === UserTypes.free}
              />
            );
            sectionSummaryComponent = <SectionSummary scaled={report.summary.score} letter={report.summary.letter} />;
          }
          break;
        case ReportSectionKeys.summaryDetails:
          if (report.summary) {
            sectionComponent = (
              <div>
                <SummaryDetails
                  report={report}
                  districtOrCampusName={reportData.districtOrCampusName}
                  districtOrCampusNumber={reportData.districtOrCampusNumber}
                  hideHeader={false}
                  schoolYear={reportData.schoolYear}
                />
              </div>
            );
            sectionComponentCampusInDistrict =
              allCampusReports &&
              allCampusReports.map((campusInfo) => (
                <div key={campusInfo.campusNumber}>
                  {campusInfo.campusResult && campusInfo.campusResult.report && campusInfo.campusResult.report.d1 && (
                    <SummaryDetails
                      report={campusInfo.campusResult.report}
                      districtOrCampusName={campusInfo.campusName}
                      districtOrCampusNumber={campusInfo.campusNumber}
                      isFreeAccount={profile.userType === UserTypes.free}
                      hideHeader={true}
                      schoolYear={reportData.schoolYear}
                    />
                  )}
                </div>
              ));
          }
          break;
        case ReportSectionKeys.d1staar:
          if (report.d1 && report.d1.staar) {
            sectionComponent = <D1Table domain={report.d1.staar} />;
            sectionSummaryComponent = (
              <SectionSummary
                raw={report.d1.staar.raw}
                scaled={report.d1.staar.scaled}
                letter={report.d1.staar.letter}
              />
            );
          }
          break;
        case ReportSectionKeys.d1ccmr:
          if (report.d1 && report.d1.ccmr) {
            sectionComponent = <D1Ccmr domain={report.d1.ccmr} />;
            sectionSummaryComponent = (
              <SectionSummary
                raw={!isNaN(report.d1.ccmr.raw) && parseFloat(report.d1.ccmr.raw).toFixed()}
                scaled={report.d1.ccmr.scaled}
                letter={report.d1.ccmr.letter}
              />
            );
          }
          break;
        case ReportSectionKeys.d1gradRate:
          if (report.d1 && report.d1.gradRate) {
            sectionComponent = <D1GradRate domain={report.d1.gradRate} />;
            sectionSummaryComponent = (
              <SectionSummary
                raw={report.d1.gradRate.raw}
                scaled={report.d1.gradRate.scaled}
                letter={report.d1.gradRate.letter}
              />
            );
          }
          break;
        case ReportSectionKeys.d2a:
          if (report.d2a) {
            sectionComponent = <D2aTable report={report} />;
            sectionSummaryComponent = (
              <SectionSummary raw={report.d2a.raw} scaled={report.d2a.scaled} letter={report.d2a.letter} />
            );
          }
          break;
        case ReportSectionKeys.d2b:
          if (report.d2b) {
            sectionComponent = <D2bTable report={report} />;
            sectionSummaryComponent = (
              <SectionSummary raw={report.d2b.raw} scaled={report.d2b.scaled} letter={report.d2b.letter} />
            );
          }
          break;
        case ReportSectionKeys.d3:
          if (report.d3) {
            sectionComponent = <D3 report={report} profile={profile} handleGetStudentsList={handleGetStudentsList} />;
            sectionSummaryComponent = (
              <SectionSummary raw={report.d3.raw} scaled={report.d3.scaled} letter={report.d3.letter} />
            );
          }
          break;
        case ReportSectionKeys.campusesWeights:
          if (report.d3) {
            sectionComponent = <CampusesWeights report={report} />;
          }
          break;
        default:
      }
    }
    return (
      <div className={classes.root} style={{ background: report ? 'white' : 'inherit' }}>
        {(loading || !profile) && <LoadingCircle classes={classes} msg={loadingMsg} />}
        <StudentsList />
        {/* <div>
          { profile && <AssessmentChecklist data={tempData} />}
        </div> */}
        <div className={classes.header} style={{ display: loading || !profile ? 'none' : 'flex' }}>
          <div style={{ width: 200 }}>
            <TitleBackButton title="A-F Reports" />
            {report && (
              <div>
                <ExportCSVButton title="Export As CSV" onClick={this.exportCsv(reportSection)} />
                <iframe
                  title="print"
                  id="ifmcontentstoprint"
                  style={{
                    height: '0px',
                    width: '0px',
                    position: 'absolute',
                  }}
                />
                <PrintButton title="Print" onClick={this.exportPdf(reportSection)} />
              </div>
            )}
          </div>
          {assessmentList && profile && (
            <div>
              <AtoFFilters
                assessmentGroups={assessmentGroups}
                profile={profile}
                jobName={JobNames.aToF}
                orientation="horizontal"
              />
            </div>
          )}
        </div>
        <div className={classes.subheader}>
          {report && (
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  label="Report Section"
                  className={classes.textField}
                  value={reportSection}
                  onChange={this.handleChange('reportSection')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {Object.entries(reportSections).map(([value, label]) => {
                    let reportDataKey = value;
                    if (value == 'summaryDetails') {
                      reportDataKey = 'summary';
                    }
                    if (
                      (report[reportDataKey] &&
                        (reportDataKey == 'campusesWeights' ||
                          report[reportDataKey].letter ||
                          report[reportDataKey].msg)) ||
                      (report.d1[reportDataKey.split('d1')[1]] &&
                        (report.d1[reportDataKey.split('d1')[1]].scaled || report.d1[reportDataKey.split('d1')[1]].msg))
                    ) {
                      let isHidden = report.ccmrSource == 'manual' && value == 'd1ccmr';
                      return (
                        !isHidden && (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        )
                      );
                    }
                    return null;
                  })}
                </TextField>
              </FormControl>
            </div>
          )}
          {sectionSummaryComponent}
        </div>

        {sectionComponent}
        {sectionComponentCampusInDistrict}
      </div>
    );
  }
}

export default withStyles(styles)(AtoFBase);
