import firebase from 'firebase';
import { combineEpics, ofType } from 'redux-observable';
import { asyncFinish, asyncProgress } from '../../../common/+store/index';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { from, merge, of } from 'rxjs';
import { map, catchError, tap, toArray, switchMap } from 'rxjs/operators';

import {
  getAssessmentList,
  createAssessmentSet,
  saveAssessmentSet,
  addAssessmentListListener,
  removeAssessmentListListener,
  deleteAssessmentSet,
  getAssessmentGroupList,
  deleteAssessmentGroup,
  addAssessmentGroup,
  updateAssessmentGroup,
  deleteCascadeAssessmentFromGroups,
  changeAssessmentSetVisibility,
  changeAssessmentGroupVisibility,
} from './assessments.db';
import {
  GET_ASSESSMENT_LIST,
  UPLOAD_ASSESSMENT,
  assessmentsUpdated,
  ADD_ASSESSMENTS_LISTENER,
  REMOVE_ASSESSMENTS_LISTENER,
  DELETE_ASSESSMENT,
  GET_ASSESSMENT_GROUPS,
  DELETE_ASSESSMENT_GROUP,
  ADD_ASSESSMENT_GROUP,
  UPDATE_ASSESSMENT_GROUP,
  DOWNLOAD_ASSESSMENT,
  REPARSE_ASSESSMENT,
  CHANGE_ASSESSMENT_VISIBILITY,
  CHANGE_ASSESSMENT_GROUP_VISIBILITY,
  UPLOAD_SDR_FILES,
} from './assessments.actions';
import { saveAssessment } from './assessments.storage';
import { onFileUpload, onAssessmentFileDownload, onSDRFilesUpload } from './assessments.api';
import { AssessmentTypes } from './assessments.types';

const getAssessmentsEpic = (action$) =>
  action$.pipe(
    ofType(GET_ASSESSMENT_LIST),
    switchMap((action) =>
      from(getAssessmentList(action.profile)).pipe(commonApiCallFragment(action$, action, 'Get Assessment List'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessments: results.response,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const addAssessmentsListenerEpic = (action$) =>
  action$.pipe(
    ofType(ADD_ASSESSMENTS_LISTENER),
    switchMap((action) =>
      from(
        addAssessmentListListener(action.profile, (assessmentList) => {
          action.dispatch(assessmentsUpdated(assessmentList));
        })
      ).pipe(commonApiCallFragment(action$, action, 'Add Assessment List Listener'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            listener: results.response,
            id: results.action.id,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const removeAssessmentsListenerEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_ASSESSMENTS_LISTENER),
    switchMap((action) =>
      from(removeAssessmentListListener(action.profile, action.listener)).pipe(
        commonApiCallFragment(action$, action, 'Remove Assessment List Listener')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            listener: results.response,
            id: results.action.id,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const uploadAssessmentEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_ASSESSMENT),
    switchMap((action) =>
      from(
        createAssessmentSet(action.profile, action.file, action.assessmentType, action.fields, action.customType)
      ).pipe(commonApiCallFragment(action$, action, 'Create assessment set'))
    ),
    switchMap((result) => {
      if (result.success) {
        const assessmentSetObj = result.response;
        return from(
          saveAssessment(result.action.profile, assessmentSetObj, result.action.file, (progress, status) => {
            if (status === firebase.storage.TaskState.PAUSED) {
              result.action.dispatch(
                asyncProgress(UPLOAD_ASSESSMENT, {
                  progress,
                  message: `${progress}% Paused`,
                })
              );
            } else if (status === firebase.storage.TaskState.RUNNING) {
              result.action.dispatch(
                asyncProgress(UPLOAD_ASSESSMENT, {
                  progress,
                  message: `${progress}%`,
                })
              );
            }
          })
        ).pipe(commonApiCallFragment(action$, result.action, 'Save assessment', { assessmentSetObj }));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return from(saveAssessmentSet(result.assessmentSetObj, result.action.profile)).pipe(
          commonApiCallFragment(action$, result.action, 'Save assessment', {
            assessmentSetObj: result.assessmentSetObj,
          })
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success && !(result.action.isUnrecognized && result.action.assessmentType === AssessmentTypes.Other)) {
        const { dispatch } = result.action;
        const { assessmentSetObj } = result;
        const [[assessmentSetId, assessmentSet]] = Object.entries(assessmentSetObj);
        dispatch(
          asyncProgress(result.action.type, {
            progress: 100,
            message: 'Processing ...',
          })
        );
        return from(
          onFileUpload({
            name: assessmentSet.file.storageKey,
            assessmentSetId,
          })
        ).pipe(commonApiCallFragment(action$, result.action, 'Get Assessment List'));
      }
      return of(result);
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessments: results.response,
            type: results.action.assessmentType,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const uploadSDRFilesEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_SDR_FILES),
    switchMap((action) => {
      const files = Array.from(action.files); // Convert FileList to array
      if (files.length) {
        // Convert all files to Base64
        const fileDataArray$ = from(files).pipe(
          map((file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  name: file.name,
                  type: file.type,
                  content: reader.result.split(',')[1], // Remove Base64 prefix
                });
              };
              reader.onerror = reject;
              reader.readAsDataURL(file); // Trigger file read
            });
          }),
          toArray(), // Collect all promises into an array
          switchMap((promises) => Promise.all(promises)) // Wait for all promises
        );

        return fileDataArray$.pipe(
          switchMap((fileDataArray) => {
            // Send all files in a single request
            return from(onSDRFilesUpload({ files: fileDataArray })).pipe(
              commonApiCallFragment(action$, action, 'Upload SDR Files')
            );
          })
        );
      }

      return of(action);
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessments: results.response,
            type: results.action.assessmentType,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const downloadAssessmentEpic = (action$) => {
  return action$.pipe(
    ofType(DOWNLOAD_ASSESSMENT),
    switchMap((action) =>
      from(onAssessmentFileDownload(action.assessment)).pipe(
        commonApiCallFragment(action$, action, 'Download Assessment')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        const fileName = results.action.assessment.name;
        const fileInfo = results.response.data;
        const blob = new Blob([fileInfo.fileContent], { type: fileInfo.contentType });
        //const blob = new Blob([results.response.data], { type: 'application/octet-stream' });
        // Trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.${fileInfo.fileExtension}`;
        link.click();
        return of(asyncFinish(results.action.type, {}));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );
};

const reparseAssessmentEpic = (action$) =>
  action$.pipe(
    ofType(REPARSE_ASSESSMENT),
    switchMap((action) => {
      return from(
        onFileUpload({
          name: action.assessment.file.storageKey,
          assessmentSetId: action.assessment.id,
          type: action.assessment.type,
          uploadedAt: action.assessment.file.uploadedAt,
          origName: action.assessment.file.origName,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Reparse Assessment'));
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessments: results.response,
            type: results.action.assessmentType,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const deleteAssessmentEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_ASSESSMENT),
    switchMap((action) =>
      from(deleteAssessmentSet(action.profile, action.assessment)).pipe(
        commonApiCallFragment(action$, action, 'Delete assessment set')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return from(getAssessmentGroupList(results.action.profile)).pipe(
          commonApiCallFragment(action$, results.action, 'Delete assessment set')
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success && results.response) {
        const assessmentId = results.action.assessment.id;
        const assessmentGroups = results.response;
        const { profile } = results.action;
        const groups = {};
        Object.entries(assessmentGroups).forEach(([groupId, group]) => {
          group.updatedBy = profile.uid;
          group.assessments = group.assessments.filter((a) => a !== assessmentId);
          // Remove a group if it doesn't have any assessments
          groups[groupId] = group.assessments.length === 0 ? null : group;
        });
        return from(deleteCascadeAssessmentFromGroups(profile, groups)).pipe(
          commonApiCallFragment(action$, results.action, 'Delete assessment set', { groups })
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        // clean up groups from null
        let assessmentGroups = null;
        if (results.groups) {
          assessmentGroups = results.groups;
          Object.keys(assessmentGroups).forEach((key) => assessmentGroups[key] == null && delete assessmentGroups[key]);
        }
        // Reset A-F Filters
        localStorage.removeItem('aToFFilters');
        return of(asyncFinish(results.action.type, { assessmentGroups }));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getAssessmentGroupsEpic = (action$) =>
  action$.pipe(
    ofType(GET_ASSESSMENT_GROUPS),
    switchMap((action) =>
      from(getAssessmentGroupList(action.profile)).pipe(commonApiCallFragment(action$, action, 'Get Assessment Groups'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessmentGroups: results.response ? results.response : {},
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const deleteAssessmentGroupEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_ASSESSMENT_GROUP),
    switchMap((action) =>
      from(deleteAssessmentGroup(action.orgId, action.assessmentGroupId)).pipe(
        commonApiCallFragment(action$, action, 'Delete assessment group')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(results.action.type, { assessmentGroupId: results.action.assessmentGroupId }));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const addAssessmentGroupEpic = (action$) =>
  action$.pipe(
    ofType(ADD_ASSESSMENT_GROUP),
    switchMap((action) =>
      from(addAssessmentGroup(action.profile, action.assessmentGroup)).pipe(
        commonApiCallFragment(action$, action, 'Add assessment group')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessmentGroupId: results.response.key,
            assessmentGroup: results.action.assessmentGroup,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const updateAssessmentGroupEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_ASSESSMENT_GROUP),
    switchMap((action) =>
      from(updateAssessmentGroup(action.profile, action.assessmentGroupId, action.assessmentGroup)).pipe(
        commonApiCallFragment(action$, action, 'Edit assessment group')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessmentGroupId: results.action.assessmentGroupId,
            assessmentGroup: results.action.assessmentGroup,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const changeAssessmentVisibilityEpic = (action$) =>
  action$.pipe(
    ofType(CHANGE_ASSESSMENT_VISIBILITY),
    switchMap((action) =>
      from(changeAssessmentSetVisibility(action.profile, action.assessment)).pipe(
        commonApiCallFragment(action$, action, 'Change assessment set Visibility')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        // Reset A-F Filters
        localStorage.removeItem('aToFFilters');
        return of(asyncFinish(results.action.type, {}));
      }
      return of(results.action);
    })
  );
const changeAssessmentGroupVisibilityEpic = (action$) =>
  action$.pipe(
    ofType(CHANGE_ASSESSMENT_GROUP_VISIBILITY),
    switchMap((action) =>
      from(changeAssessmentGroupVisibility(action.profile, action.assessmentGroup)).pipe(
        commonApiCallFragment(action$, action, 'Change assessment group Visibility')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessmentGroupId: results.action.assessmentGroup.id,
            assessmentGroup: results.action.assessmentGroup,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(
  getAssessmentsEpic,
  uploadAssessmentEpic,
  uploadSDRFilesEpic,
  downloadAssessmentEpic,
  reparseAssessmentEpic,
  addAssessmentsListenerEpic,
  removeAssessmentsListenerEpic,
  deleteAssessmentEpic,
  getAssessmentGroupsEpic,
  deleteAssessmentGroupEpic,
  addAssessmentGroupEpic,
  updateAssessmentGroupEpic,
  changeAssessmentVisibilityEpic,
  changeAssessmentGroupVisibilityEpic
);
export default epics;
